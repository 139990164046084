import { useMemo } from 'react'

import defaultFavicon from 'assets/wpp-favicon.ico'
import { htmlDefaultValues } from 'constants/html'
import { useOsRoot } from 'providers/osRoot/OsRootContext'

export const useTenantLogoAndMetaData = () => {
  const { hostInfo, defaultTenant } = useOsRoot()

  const { currentTenant } = hostInfo

  return useMemo(
    () => ({
      logoOriginal: currentTenant?.logoOriginal?.url || defaultTenant.logoOriginal?.url || undefined,
      logoThumbnail: currentTenant?.logoThumbnail?.url || defaultTenant.logoThumbnail?.url || undefined,
      favicon: currentTenant?.favicon?.url || defaultTenant.favicon?.url || defaultFavicon,
      loginBackground: currentTenant?.loginBackground?.url || defaultTenant.loginBackground?.url || undefined,
      name: currentTenant?.name || htmlDefaultValues.name,
      title: currentTenant?.title || defaultTenant.title || htmlDefaultValues.title,
      description: currentTenant?.description || defaultTenant.description || htmlDefaultValues.description,
      id: currentTenant?.id || null,
    }),
    [currentTenant, defaultTenant],
  )
}
