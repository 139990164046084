import { TreeType } from '@platform-ui-kit/components-library'
import { HierarchyContainerNodeId, HierarchyNode, NavigationTreeMapping } from '@wpp-open/core'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { AzGroup, TenantGroup } from 'types/groups/groups'
import { MemberRole } from 'types/roles/memberRole'
import { RoleMember } from 'types/roles/roleMember'
import { Role, RoleSource } from 'types/roles/roles'
import { ShortTenantRole, TenantRole } from 'types/roles/tenantRole'
import { AZUser, UserDetailsShort } from 'types/users/users'
import { isHierarchyNode } from 'utils/navigation'

type TreeItemConfig = Omit<TreeType, 'children' | 'id' | 'title'>

interface UniqueAccounts {
  id: string
  name: string
}
interface AssignmentsDirect {
  assignmentId: string
  accountId: string
  roleId: string
}

const mapNode = (node: HierarchyNode, mapping: NavigationTreeMapping, childrenConfig?: TreeItemConfig): TreeType => {
  const { children, name, azId } = node
  const filteredChildren = children.map(childId => mapping[childId]).filter(isHierarchyNode)

  return {
    title: name || '',
    id: azId,
    ...(!!filteredChildren.length && {
      children: filteredChildren.map(childNode => mapNode(childNode, mapping, childrenConfig)),
    }),
    ...childrenConfig,
  }
}

export const useMapNavigationTree = () => {
  const { t } = useTranslation()
  const {
    navigationTreeWithHiddenLevel: { mapping, rootId },
  } = useCurrentTenantData()

  return {
    mapNavigationTree: useCallback(
      (osConfig?: TreeItemConfig): TreeType[] => {
        const rootChildren = (mapping[HierarchyContainerNodeId]?.children || [])
          .map(childId => mapping[childId])
          .filter(isHierarchyNode)

        return [
          {
            title: t('os.common.navigation.os'),
            open: true,
            id: rootId,
            ...(!!rootChildren.length && {
              children: rootChildren.map(childNode => mapNode(childNode, mapping)),
            }),
            ...osConfig,
          },
        ]
      },
      [mapping, rootId, t],
    ),
  }
}

const getGroupId = (group: TenantGroup | AzGroup) => ('id' in group ? group.id : group.uid)

export const isRoleCreatedOnRoot = (accountName?: string) => !!accountName && accountName === 'ROOT'

export const getRoleSource = (role: TenantRole): RoleSource => ({
  id: role.createdOnAccountId,
  name: role.createdOnAccountName,
})

export const getMemberRolesPayload = (email: string, roleId: string, accountId: string) => ({
  user_id: email,
  role_id: roleId,
  account_id: accountId,
})

export const getGroupRolesPayload = (groupId: string, roleId: string, accountId: string) => ({
  group_id: groupId,
  role_id: roleId,
  account_id: accountId,
})

export const mapGroupRolesPayloadToCreate = (groups: (TenantGroup | AzGroup)[], rolesAssignments: TenantRole[]) => {
  return rolesAssignments
    ? groups.flatMap(group =>
        rolesAssignments.flatMap(role =>
          role.uniqueAccounts.map(({ id }) => getGroupRolesPayload(getGroupId(group), role.id, id)),
        ),
      )
    : []
}

export const mapMemberRolesPayloadToCreate = (
  members: (UserDetailsShort | { email: string; lastname: string; firstname: string })[],
  rolesAssignments: TenantRole[],
) => {
  return rolesAssignments
    ? members.flatMap(member =>
        rolesAssignments.flatMap(role =>
          role.uniqueAccounts.map(({ id }) => getMemberRolesPayload(member.email, role.id, id)),
        ),
      )
    : []
}

export const mapGroupRolesPayloadToDelete = (group: AzGroup, role: ShortTenantRole) => {
  return role.uniqueAccounts.length
    ? role.uniqueAccounts.map(({ id }) => getGroupRolesPayload(group.uid, role.id, id))
    : [getGroupRolesPayload(group.uid, role.id, group.account_uid)]
}

const getAccountId = (account: UniqueAccounts | AssignmentsDirect) => ('id' in account ? account.id : account.accountId)

export const mapMemberRolesPayloadToDelete = (
  member: AZUser | RoleMember,
  role: MemberRole | Role,
  accountId: string,
) => {
  const roleUniqueAccounts = 'uniqueAccounts' in role ? role.uniqueAccounts : null
  const memberAssignmentsDirect = 'assignmentsDirect' in member ? member.assignmentsDirect : null

  const firstDefinedAccounts = [roleUniqueAccounts, memberAssignmentsDirect].find(
    accounts => !!accounts && accounts.length,
  )

  return firstDefinedAccounts
    ? firstDefinedAccounts.map(account => getMemberRolesPayload(member.email, role.id, getAccountId(account)))
    : [getMemberRolesPayload(member.email, role.id, accountId)]
}

export const mapMembersRolesPayloadToDelete = (members: RoleMember[], role: MemberRole | Role, accountId: string) => {
  return members
    .map(member => {
      const memberAssignmentsDirect = 'assignmentsDirect' in member ? member.assignmentsDirect : null

      const accounts = [memberAssignmentsDirect].find(accounts => !!accounts && accounts.length)

      return accounts
        ? accounts.map(account => getMemberRolesPayload(member.email, role.id, getAccountId(account)))
        : getMemberRolesPayload(member.email, role.id, accountId)
    })
    .flat()
}
