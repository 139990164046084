import { WppTypography } from '@platform-ui-kit/components-library-react'
import {
  AnalyticsActionType,
  getVisibleHierarchyLevels,
  HierarchyContainerNodeId,
  HierarchyLevelType,
} from '@wpp-open/core'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Avatar } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import { showRequestHierarchyAccessModal } from 'components/modal/requestHierarchyAccessModal/RequestHierarchyAccessModal'
import styles from 'components/navigationMenu/navigationMenuContent/hierarchyNavigationMenuColumn/HierarchyNavigationMenuColumn.module.scss'
import { NavigationMenuColumnHeader } from 'components/navigationMenu/navigationMenuContent/navigationMenuColumnHeader/NavigationMenuColumnHeader'
import { useNavigationMenuController } from 'components/navigationMenu/navigationMenuContent/NavigationMenuControllerContext'
import { NavigationMenuTreeListColumn } from 'components/navigationMenu/navigationMenuContent/navigationMenuTreeListColumn/NavigationMenuTreeListColumn'
import { HierarchyTreeList } from 'components/treeList/hierarchyTreeList/HierarchyTreeList'
import { SearchableTreeList } from 'components/treeList/searchableTreeList/SearchableTreeList'
import { TreeListItem } from 'components/treeList/treeListItem/TreeListItem'
import { DEFAULT_PLURAL_COUNT } from 'constants/i18n'
import { useHeaderNavigation } from 'layout/header/headerNavigationProvider/HeaderNavigationProvider'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { trackAnalytics } from 'utils/analytics'
import {
  getWorkspacePointerNodes,
  useGetFirstLevelNodesLogoUrl,
  getNextHierarchyLevelType,
  getHierarchyLevelTKey,
} from 'utils/mapping/common'

export const HierarchyNavigationMenuColumn = () => {
  const { t } = useTranslation()
  const { navigationHierarchy, currentTenant, navigationTree } = useCurrentTenantData()
  const { nodesMapping } = useHeaderNavigation()
  const { selectedNavigationNodeId, selectNavigationNode } = useNavigationMenuController()
  const getNodeLogoUrl = useGetFirstLevelNodesLogoUrl({
    tree: navigationTree,
    hierarchyLevels: navigationHierarchy,
  })
  const [search, setSearch] = useState('')
  const [isEmptyResult, setIsEmptyResult] = useState(false)

  const workspacePointerNodes = getWorkspacePointerNodes({
    pointerNode: nodesMapping[selectedNavigationNodeId],
    navigationHierarchy,
  })

  const pointerNodes = nodesMapping[HierarchyContainerNodeId].children

  const isHierarchySet = navigationHierarchy.length > 0
  const isFirstHierarchyLevelVisible = !workspacePointerNodes.length || navigationHierarchy.length === 1
  const lastWorkspaceNode = workspacePointerNodes.at(-1)

  const levelType = getNextHierarchyLevelType({
    type: (lastWorkspaceNode?.node.type as HierarchyLevelType) || HierarchyLevelType.Tenant,
    hierarchyLevels: getVisibleHierarchyLevels(currentTenant),
  })

  const levelTKey = getHierarchyLevelTKey(levelType)
  const levelLabelPlural = t(levelTKey, { count: DEFAULT_PLURAL_COUNT })

  const handleSearch = (searchValue: string, isEmpty: boolean) => {
    setSearch(searchValue)
    setIsEmptyResult(isEmpty)
  }

  return (
    <NavigationMenuTreeListColumn data-testid="hierarchy-navigation-menu-column">
      <NavigationMenuColumnHeader>
        <WppTypography type="l-strong" data-testid="hierarchy-navigation-menu-column-title">
          {levelLabelPlural}
        </WppTypography>
      </NavigationMenuColumnHeader>

      {isHierarchySet ? (
        <>
          {isFirstHierarchyLevelVisible ? (
            <SearchableTreeList
              pointerNodes={pointerNodes}
              onSearch={handleSearch}
              inputPlaceholder={t('os.navigation_menu.search_placeholder', {
                entity: levelLabelPlural.toLocaleLowerCase(),
              })}
              renderNode={({ nodeId, node: { azId, name, logoThumbnail, type } }) => (
                <TreeListItem
                  checked={selectedNavigationNodeId === nodeId}
                  onClick={() => {
                    if (type === HierarchyLevelType.Client) {
                      trackAnalytics({
                        type: AnalyticsActionType.action,
                        payload: {
                          action: 'Recent Clients',
                          params: [
                            { key: 'clientId', value: azId! },
                            { key: 'tenantId', value: currentTenant.id },
                          ],
                        },
                      })
                    }

                    selectNavigationNode(nodeId)
                  }}
                >
                  <Avatar slot="left" src={getNodeLogoUrl(logoThumbnail?.key)} name={name} />
                  <span slot="label">{name}</span>
                </TreeListItem>
              )}
              emptyState={
                search ? (
                  <Flex direction="column" gap={8}>
                    <WppTypography data-testid="no-hierarchy-item-label" type="s-midi">
                      {t('os.navigation_menu.no_searchable_hierarchy_items.title', { search })}
                    </WppTypography>
                    <WppTypography type="xs-body">
                      <Trans
                        i18nKey="os.navigation_menu.no_searchable_hierarchy_items.subtitle"
                        values={{ requestAccess: t('os.navigation_menu.request_access.subtitle').toLowerCase() }}
                        components={[
                          <span
                            key="0"
                            onClick={() => showRequestHierarchyAccessModal({ tenantId: currentTenant.id })}
                            className={styles.requestAccess}
                          >
                            requestAccess
                          </span>,
                        ]}
                      />
                    </WppTypography>
                  </Flex>
                ) : (
                  <WppTypography type="s-midi">
                    {t('os.navigation_menu.no_hierarchy_items', { levelName: levelLabelPlural })}
                  </WppTypography>
                )
              }
            />
          ) : (
            <HierarchyTreeList
              hierarchyLevels={navigationHierarchy}
              workspacePointerNodes={workspacePointerNodes}
              nodesMapping={nodesMapping}
              onParentNodeClick={({ parent }) => selectNavigationNode(parent!.nodeId)}
              getNodeLogoUrl={getNodeLogoUrl}
              renderNode={({ nodeId, node: { name } }) => (
                <TreeListItem
                  checked={selectedNavigationNodeId === nodeId}
                  onClick={e => {
                    if (!e.currentTarget.checked) {
                      selectNavigationNode(nodeId)
                    }
                  }}
                >
                  <span slot="label">{name}</span>
                </TreeListItem>
              )}
              emptyState={
                <WppTypography type="s-midi">
                  {t('os.navigation_menu.no_hierarchy_items', { levelName: levelLabelPlural })}
                </WppTypography>
              }
            />
          )}
        </>
      ) : (
        <WppTypography data-testid="hierarchy-not-configured-label" className={styles.emptyState} type="s-midi">
          {t('os.navigation_menu.hierarchy_not_configured')}
        </WppTypography>
      )}
      {isHierarchySet && !isEmptyResult && (
        <Flex
          direction="column"
          className={isFirstHierarchyLevelVisible ? styles.requestAccessFirstLevelWrapper : styles.requestAccessWrapper}
        >
          <WppTypography type="s-strong">
            {t('os.navigation_menu.request_access.title', { levelName: t(levelTKey) })}
          </WppTypography>
          <WppTypography
            type="s-body"
            className={styles.requestAccess}
            onClick={() => showRequestHierarchyAccessModal({ tenantId: currentTenant.id })}
          >
            {t('os.navigation_menu.request_access.subtitle')}
          </WppTypography>
        </Flex>
      )}
    </NavigationMenuTreeListColumn>
  )
}
