import clsx from 'clsx'

import styles from 'components/svg/common.module.scss'

export const SvgNoNotifications = (props: JSX.IntrinsicElements['svg']) => (
  <svg width="200" height="160" viewBox="0 0 200 160" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_1878_65329)">
      <g filter="url(#filter0_f_1878_65329)">
        <ellipse cx="93.8723" cy="131.941" rx="71.8332" ry="12.9414" className={styles.fillPrimary300} />
      </g>
      <path
        d="M100 159C141.421 159 175 125.421 175 84C175 42.5786 141.421 9 100 9C58.5786 9 25 42.5786 25 84C25 125.421 58.5786 159 100 159Z"
        className={styles.fillPrimary200}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M146 37.2857C147.262 37.2857 148.286 36.2624 148.286 35C148.286 33.7376 147.262 32.7143 146 32.7143C144.738 32.7143 143.714 33.7376 143.714 35C143.714 36.2624 144.738 37.2857 146 37.2857ZM146 39C148.209 39 150 37.2091 150 35C150 32.7909 148.209 31 146 31C143.791 31 142 32.7909 142 35C142 37.2091 143.791 39 146 39Z"
        className={styles.fillPrimary300}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M126 155.286C127.262 155.286 128.286 154.262 128.286 153C128.286 151.738 127.262 150.714 126 150.714C124.738 150.714 123.714 151.738 123.714 153C123.714 154.262 124.738 155.286 126 155.286ZM126 157C128.209 157 130 155.209 130 153C130 150.791 128.209 149 126 149C123.791 149 122 150.791 122 153C122 155.209 123.791 157 126 157Z"
        className={styles.fillPrimary300}
      />
      <path
        d="M14.2985 114.031C16.0586 113.744 17.4816 115.525 16.8456 117.219L15.9838 119.515C15.3544 121.192 13.1761 121.526 12.0518 120.119L10.5283 118.211C9.40405 116.804 10.1712 114.703 11.9131 114.419L14.2985 114.031Z"
        className={styles.fillPrimary300}
      />
      <path
        d="M164.067 96.8329C163.656 95.1412 165.209 93.6304 166.894 94.0804L170.289 94.9865C172.014 95.4469 172.582 97.6046 171.306 98.8468L168.737 101.347C167.461 102.589 165.312 101.97 164.892 100.239L164.067 96.8329Z"
        className={styles.fillPrimary300}
      />
      <path
        d="M23.0064 95.4807C23.5384 93.5064 26.4616 93.5064 26.9936 95.4807L27.2725 96.5155C27.4656 97.2321 28.0604 97.7851 28.8133 97.9478L29.3937 98.0733C31.5354 98.5364 31.5354 101.464 29.3937 101.927L28.8133 102.052C28.0604 102.215 27.4656 102.768 27.2725 103.485L26.9936 104.519C26.4616 106.494 23.5384 106.494 23.0064 104.519L22.7275 103.485C22.5344 102.768 21.9396 102.215 21.1867 102.052L20.6063 101.927C18.4646 101.464 18.4646 98.5364 20.6063 98.0733L21.1867 97.9478C21.9396 97.7851 22.5344 97.2321 22.7275 96.5155L23.0064 95.4807Z"
        className={styles.fillPrimary400}
      />
      <g clipPath="url(#clip1_1878_65329)">
        <path
          d="M85.5967 41.6351C68.2841 47.1592 58.8422 65.8783 63.5456 83.4315L68.4754 101.83L65.3516 117.285C64.7034 120.893 68.0252 123.95 71.5654 123.002L141.583 104.241C145.123 103.292 146.474 98.983 144.108 96.1823L133.675 84.3597L128.499 65.0412C123.535 46.5148 104.083 35.7371 85.5967 41.6351Z"
          className={clsx(styles.fillWhite, styles.strokePrimary200)}
          strokeWidth="5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx="86.7361"
          cy="43.7348"
          r="5.5"
          transform="rotate(-15 86.7361 43.7348)"
          className={styles.fillWhite}
        />
        <path
          d="M118.284 113.848C119.892 119.849 116.33 126.019 110.329 127.627C104.328 129.235 98.1587 125.673 96.5508 119.672"
          className={styles.fillWhite}
        />
      </g>
      <path
        d="M124.006 10.4807C124.538 8.50642 127.462 8.50642 127.994 10.4807L128.272 11.5155C128.466 12.2321 129.06 12.7851 129.813 12.9478L130.394 13.0733C132.535 13.5364 132.535 16.4636 130.394 16.9267L129.813 17.0522C129.06 17.2149 128.466 17.7679 128.272 18.4845L127.994 19.5193C127.462 21.4936 124.538 21.4936 124.006 19.5193L123.728 18.4845C123.534 17.7679 122.94 17.2149 122.187 17.0521L121.606 16.9267C119.465 16.4636 119.465 13.5364 121.606 13.0733L122.187 12.9478C122.94 12.7851 123.534 12.2321 123.728 11.5155L124.006 10.4807Z"
        className={styles.fillPrimary400}
      />
    </g>
    <defs>
      <filter
        id="filter0_f_1878_65329"
        x="0.266068"
        y="97.227"
        width="187.212"
        height="69.4288"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="10.8865" result="effect1_foregroundBlur_1878_65329" />
      </filter>
      <clipPath id="clip0_1878_65329">
        <rect width="200" height="160" className={styles.fillWhite} />
      </clipPath>
      <clipPath id="clip1_1878_65329">
        <rect width="120" height="120" className={styles.fillWhite} transform="translate(48 26)" />
      </clipPath>
    </defs>
  </svg>
)
