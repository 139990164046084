import NiceModal, { NiceModalHandler, useModal as useNiceModal } from '@ebay/nice-modal-react'
import { FC, useCallback, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { useStableCallback } from 'hooks/useStableCallback'

export interface NiceModalWrappedProps {
  id: string
  isOpen: boolean
  onClose: () => void
  onOpen?: () => void
  onCloseComplete: () => void
}

type OmittedProps<T> = Omit<T, 'isOpen' | 'onClose' | 'onCloseComplete' | 'id'>

type ShowModalProps<T> = OmittedProps<T> & { onClose?: () => void }

export function createNiceModal<T extends NiceModalWrappedProps>(
  Component: FC<T>,
  id: string,
  dependencies: (() => NiceModalHandler)[] = [],
) {
  const WrappedComponent = NiceModal.create<T>((props: T) => {
    const { visible, hide, remove } = useNiceModal()
    const { pathname } = useLocation()

    const { onOpen, onClose } = props

    const handlers = dependencies.map(hook => hook())
    const keepMounting = handlers.filter(handler => handler.visible).length > 0

    const onCloseInner = useStableCallback(() => {
      hide()
      onClose?.()
    })

    const onAutoClose = useStableCallback(() => {
      remove()
      handlers.forEach(handler => handler.remove())
    })

    useEffect(() => {
      if (visible) {
        onOpen?.()
      }
    }, [onOpen, visible])

    useEffect(() => () => onAutoClose(), [pathname, onAutoClose])

    const onCloseComplete = useCallback(() => !keepMounting && remove(), [remove, keepMounting])

    return (
      <Component
        {...{
          ...props,
          isOpen: visible,
          onClose: onCloseInner,
          onCloseComplete,
          id,
        }}
      />
    )
  })

  NiceModal.register(id, WrappedComponent)

  const showModal = (props?: ShowModalProps<T>) => NiceModal.show(id, props)

  const hideModal = () => NiceModal.hide(id)

  const useModal = () => useNiceModal(id)

  return {
    showModal,
    hideModal,
    useModal,
  }
}
