import { Provider as NiceModalProvider } from '@ebay/nice-modal-react'
import { MayBeNull } from '@wpp-open/core'
import { SigninRedirectArgs } from 'oidc-client-ts'
import { useAuth } from 'react-oidc-context'
import { Navigate, useLocation } from 'react-router-dom'

import { useTenantHomeUrl } from 'hooks/useTenantHomeUrl'
import { LoginPage } from 'pages/login/LoginPage'
import { IdentityProvider } from 'types/auth/identityProvider'
import { SignInUserState } from 'types/auth/state'

export const Login = () => {
  const { isAuthenticated, signinRedirect } = useAuth()
  const { state } = useLocation()

  const historyState = state as SignInUserState | undefined
  const defaultTargetUrl = useTenantHomeUrl()

  const handleLogin = (identityProvider?: IdentityProvider) => {
    const providerData: MayBeNull<SigninRedirectArgs> = identityProvider
      ? {
          extraQueryParams: {
            kc_idp_hint: identityProvider,
          },
        }
      : null
    signinRedirect({
      ...providerData,
      state: {
        targetUrl: historyState?.targetUrl,
      } as SignInUserState,
    })
  }

  return (
    <NiceModalProvider>
      {isAuthenticated ? (
        <Navigate to={historyState?.targetUrl || defaultTargetUrl} />
      ) : (
        <LoginPage login={handleLogin} />
      )}
    </NiceModalProvider>
  )
}
