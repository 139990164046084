import { WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'

import styles from 'components/cellRenderers/avatarWithNameAndEmail/AvatarWithNameAndEmailCell.module.scss'
import { Avatar } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import { MeTag } from 'components/common/meTag/MeTag'
import { useOtherTenantsAndUserData } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'
import { mailto } from 'utils/links'

export interface Props {
  avatarThumbnail: string
  userName: string
  email?: string
}

export const AvatarWithNameAndEmailCell = ({ avatarThumbnail, userName, email }: Props) => {
  const { userDetails } = useOtherTenantsAndUserData()
  const isCurrentUser = email === userDetails.email

  return (
    <Flex className={styles.flexBaseWidth} align="center">
      <Avatar size="s" src={avatarThumbnail} name={userName} className={styles.avatar} />

      <Flex className={styles.flexBaseWidth} direction="column">
        {isCurrentUser ? (
          <Flex className={styles.flexBaseWidth} gap={4}>
            <WppTypography className={styles.flexBaseWidth} type="s-body">
              {userName}
            </WppTypography>
            <MeTag />
          </Flex>
        ) : (
          <WppTypography className={styles.flexBaseWidth} type="s-body">
            {userName}
          </WppTypography>
        )}
        {!!email && (
          <a href={mailto(email)} className={clsx(styles.flexBaseWidth, styles.link)}>
            <WppTypography type="xs-body" className={clsx(styles.flexBaseWidth, styles.email)}>
              {email}
            </WppTypography>
          </a>
        )}
      </Flex>
    </Flex>
  )
}
