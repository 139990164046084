import { WppIconExternalLink, WppIconWarning, WppTooltip } from '@platform-ui-kit/components-library-react'
import { AnalyticsActionType } from '@wpp-open/core'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Avatar } from 'components/common/avatar/Avatar'
import { TreeListItem } from 'components/treeList/treeListItem/TreeListItem'
import { useOsState } from 'providers/osState/OsStateProvider'
import { AppInstanceShort } from 'types/apps/appInstances'
import { NoCodeAppType } from 'types/apps/noCodeApps'
import { MiroConfigStatus } from 'types/miro'
import { trackAnalytics } from 'utils/analytics'
import { mapAppInstanceToApp } from 'utils/appInstances'
import { EVENTS, getEventPayload } from 'utils/events'
import { routesManager } from 'utils/routesManager'

interface Props {
  appInstance: AppInstanceShort
  logoUrl: string
}

export const AppNavigationMenuItem = ({ appInstance, logoUrl }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { closeNavigation, appData, miroConfigStatus } = useOsState()

  const app = mapAppInstanceToApp(appInstance)

  const handleTrackAnalytics = () => {
    trackAnalytics({
      type: AnalyticsActionType.action,
      payload: getEventPayload(EVENTS.HAMBURGER_MENU.ACTIONS.ACTION_HUMBURGER_MENU_ITEM, app.name),
    })
  }

  if (app.type === NoCodeAppType.ExternalLink) {
    return (
      <TreeListItem
        linkConfig={{
          target: '_blank',
          rel: 'noreferrer',
          href: app.url,
        }}
        onClick={() => handleTrackAnalytics()}
      >
        <Avatar size="xs" slot="left" name={app.name} src={logoUrl} />
        <span slot="label">{app.name}</span>
        <WppIconExternalLink slot="right" />
      </TreeListItem>
    )
  }

  const isActive = !!appData.app && appData.app.stableId === app.stableId
  const href = routesManager.app({
    id: app.stableId,
    osRoute: app.osRoute,
  })
  const isMiroWarningVisible =
    miroConfigStatus?.status !== MiroConfigStatus.Valid && app.type === NoCodeAppType.MiroBoard

  return (
    <TreeListItem
      checked={isActive}
      linkConfig={{
        href,
        onClick: e => e.preventDefault(),
      }}
      onClick={e => {
        if (!e.currentTarget.checked) {
          navigate(href)
          closeNavigation()
          handleTrackAnalytics()
        }
      }}
    >
      <Avatar size="xs" slot="left" name={app.name} src={logoUrl} />
      <span slot="label">{app.name}</span>

      {isMiroWarningVisible && (
        <WppTooltip slot="right" text={t('os.navigation_menu.miro_app_warning_text')}>
          <WppIconWarning />
        </WppTooltip>
      )}
    </TreeListItem>
  )
}
