import { MayBeNull } from '@wpp-open/core'

import { Feed } from 'types/feeds/feeds'
import { difference } from 'utils/common'

export const enum NotificationSegment {
  ALL = 'all',
  OS = 'os',
  PROJECTS = 'projects',
  APPS = 'apps',
}

export const isToday = (date: string) => {
  const dateToCompare = new Date(date)
  const today = new Date()
  return (
    dateToCompare.getDate() == today.getDate() &&
    dateToCompare.getMonth() == today.getMonth() &&
    dateToCompare.getFullYear() == today.getFullYear()
  )
}

interface FeedsListProps {
  feeds: Feed[]
  lastSeenAt?: MayBeNull<string>
}

export const getFeedsLists = ({ lastSeenAt, feeds }: FeedsListProps) => {
  if (!lastSeenAt) {
    return { newItems: feeds, todayItems: [], earlierItems: [] }
  } else {
    const newItems = feeds.filter((item: Feed) => new Date(item.createdAt).getTime() > new Date(lastSeenAt).getTime())

    const todayItems = difference(feeds, newItems, ({ id }) => id).filter(({ createdAt }) => isToday(createdAt))

    const earlierItems = difference(feeds, [...newItems, ...todayItems], ({ id }) => id)

    return { newItems, todayItems, earlierItems }
  }
}
