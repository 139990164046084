import { ChildHierarchyLevelType, HierarchyLevel, NavigationTree, PermissionsRecord, Tenant } from '@wpp-open/core'
import { createContext, useContext } from 'react'

export interface CurrentTenantDataContextValue {
  currentTenant: Tenant
  permissions: PermissionsRecord[]
  /**
   * First hierarchy level might be hidden (removed from hierarchy)
   */
  navigationHierarchy: HierarchyLevel<ChildHierarchyLevelType>[]
  /**
   * Includes first hierarchy level even if it's hidden
   */
  navigationHierarchyWithHiddenLevel: HierarchyLevel<ChildHierarchyLevelType>[]
  /**
   * First hierarchy level might be hidden (removed from navigation tree)
   */
  navigationTree: NavigationTree
  /**
   * Includes an item from first hierarchy level even if it's hidden
   */
  navigationTreeWithHiddenLevel: NavigationTree
}

export const CurrentTenantDataContext = createContext<CurrentTenantDataContextValue>(null!)

export const useCurrentTenantData = () => useContext(CurrentTenantDataContext)
