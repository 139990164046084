import { lazy } from 'react'
import { Route } from 'react-router-dom'

import { PermittedAccess } from 'components/common/PermittedAccess'
import { SuspenseLoader } from 'components/common/SuspenseLoader'
import { NotFoundError } from 'components/renderError'
import { TENANT_SUBDOMAIN_WPP } from 'constants/common'
import { Permission } from 'constants/permission'
import { HomePageLayout } from 'pages/home/homePageLayout/HomePageLayout'
import { TenantHostInfo } from 'types/tenants/tenant'
import { routesManager } from 'utils/routesManager'

const WppOpen = lazy(() => import('pages/wppOpenDashboard/WppOpenDashboard'))
const Dashboard = lazy(() => import('pages/home/dashboard/Dashboard'))
const DashboardEdit = lazy(() => import('pages/home/dashboardEdit/DashboardEdit'))

export const getHomeRoutes = ({ currentTenant }: TenantHostInfo) => {
  const { subdomain } = currentTenant

  const isWppTenant = subdomain === TENANT_SUBDOMAIN_WPP
  const isHardcodedHome = isWppTenant

  if (isHardcodedHome) {
    return (
      <Route path={routesManager.home.root.routePath}>
        <Route
          index
          element={
            <SuspenseLoader>
              <WppOpen />
            </SuspenseLoader>
          }
        />
        <Route path="*" element={<NotFoundError />} />
      </Route>
    )
  }

  return (
    <Route path={routesManager.home.root.routePath} element={<HomePageLayout />}>
      <Route index element={<Dashboard />} />

      <Route
        path={routesManager.home.edit.routePath}
        element={
          <PermittedAccess permission={Permission.OS_WIDGETS_ENTITIES_MANAGE}>
            <DashboardEdit />
          </PermittedAccess>
        }
      />

      <Route path="*" element={<NotFoundError />} />
    </Route>
  )
}
