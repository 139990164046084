import { MicroAppCustomProps } from '@wpp-open/core'
import { Dispatch, SetStateAction, createContext, useContext } from 'react'

import { MicroAppConfig } from 'types/apps/microApps'

export interface MicroAppsContextValue {
  isMicroAppActive: boolean
  setIsMicroAppActive: Dispatch<SetStateAction<boolean>>
  getMicroAppCustomProps: (app: Pick<MicroAppConfig, 'stableId'>) => MicroAppCustomProps
  unsubscribeApp: (app: Pick<MicroAppConfig, 'stableId'>) => void
}

export const MicroAppsContext = createContext<MicroAppsContextValue>(null!)

export const useMicroAppsContext = () => useContext(MicroAppsContext)
