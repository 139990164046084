import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'

import { ForcedApiError } from 'api/common/types'
import { useAllTenantsApi } from 'api/tenant/queries/useAllTenantsApi'
import { useAvailableTenantsApi } from 'api/tenant/queries/useAvailableTenantsApi'
import { useCurrentUserApi } from 'api/users/queries/useCurrentUserApi'
import { is400Error, is403Error } from 'api/utils'
import { useIntercomUser } from 'components/intercom/utils'
import { ForbiddenOSAccessError, OsIsNotAvailableError } from 'components/renderError'
import { useProviderNoncriticalError } from 'hooks/useProviderNoncriticalError'
import { LoadingPage } from 'layout/loadingPage/LoadingPage'
import { OtherTenantsAndUserDataContext } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'
import { UserSetupLoadingPage } from 'providers/otherTenantsAndUserData/userSetupLoadingPage/UserSetupLoadingPage'

export const OtherTenantsAndUserDataProvider = () => {
  const { t } = useTranslation()

  const {
    isLoading: isUserLoading,
    data: userDetails,
    error: userDetailsError,
    response: userDetailsResponse,
  } = useCurrentUserApi()

  useIntercomUser(userDetails)

  const isUserBeingCreated = !!userDetails && userDetailsResponse?.status === 201

  // Note: Tenant queries are dependent on the user because new users are created
  // during the first call on the backend, so tenants are not available before that happens.
  const {
    data: availableTenants,
    isLoading: isAvailableTenantsLoading,
    isError: isAvailableTenantsError,
  } = useAvailableTenantsApi({
    enabled: !!userDetails,
    // Long polling while user is being created in several services.
    // No available tenants most likely means the user account is not ready yet.
    forceError: data => isUserBeingCreated && !!data && data.length === 0,
    // Retry for at least 18 * 5000ms = 90s. Plus the time for the actual requests.
    retry: (failureCount, error) => {
      const isEmptyListError = error instanceof ForcedApiError
      const isNotResolvedError = is400Error(error)

      return failureCount <= 18 && isUserBeingCreated && (isEmptyListError || isNotResolvedError)
    },
    retryDelay: 5000,
  })

  const {
    data: allTenants,
    isLoading: isAllTenantsLoading,
    isError: isAllTenantsError,
  } = useAllTenantsApi({
    enabled: !!userDetails,
  })

  useProviderNoncriticalError({
    isError: isAvailableTenantsError || isAllTenantsError,
    message: t('os.provider_errors.available_tenants'),
  })

  const requestableTenants = useMemo(
    () =>
      (allTenants || [])
        .filter(tenant => !availableTenants?.find(availableTenant => availableTenant.id === tenant.id))
        .filter(({ flags }) => !flags.onlyAdminsAccessible && flags.accessRequestable)
        .sort((a, b) => a.name.localeCompare(b.name)),
    [allTenants, availableTenants],
  )

  const sortedAvailableTenants = useMemo(
    () => availableTenants?.sort((a, b) => a.name.localeCompare(b.name)),
    [availableTenants],
  )

  const isLoading = isAvailableTenantsLoading || isAllTenantsLoading || isUserLoading

  if (isUserBeingCreated && isLoading) {
    return <UserSetupLoadingPage />
  }

  if (userDetailsError) {
    return is403Error(userDetailsError) ? <ForbiddenOSAccessError /> : <OsIsNotAvailableError />
  }

  if (isLoading) {
    return <LoadingPage />
  }

  return (
    <OtherTenantsAndUserDataContext.Provider
      value={{
        availableTenants: sortedAvailableTenants!,
        requestableTenants,
        userDetails: userDetails!,
      }}
    >
      <Outlet />
    </OtherTenantsAndUserDataContext.Provider>
  )
}
