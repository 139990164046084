import { WppIconChevron } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { Fragment, ReactNode } from 'react'

import { Flex } from 'components/common/flex/Flex'
import { useGroupAppInstances } from 'components/treeList/appsTreeList/utils'
import { LoadingTreeList } from 'components/treeList/loadingTreeList/LoadingTreeList'
import { TreeListItem } from 'components/treeList/treeListItem/TreeListItem'
import { AppInstanceShort } from 'types/apps/appInstances'

interface Props {
  isLoading: boolean
  selectedGroupId: MayBeNull<string>
  onSelectGroup: (groupId: string) => void
  appInstances: AppInstanceShort[]
  renderApp: (appInstance: AppInstanceShort) => JSX.Element
  emptyState?: ReactNode
  action?: ReactNode
}

export const CustomNavigationAppsList = ({
  isLoading,
  selectedGroupId,
  onSelectGroup,
  appInstances,
  renderApp,
  emptyState = null,
  action = null,
}: Props) => {
  const { ungrouped, grouped } = useGroupAppInstances({ appInstances })

  if (isLoading) {
    return <LoadingTreeList />
  }

  return (
    <Flex direction="column" gap={2} data-testid="apps-tree-list">
      {grouped.map(({ group }) => (
        <TreeListItem
          key={group.id}
          checked={selectedGroupId === group.id}
          onClick={e => {
            if (!e.currentTarget.checked) {
              onSelectGroup(group.id)
            }
          }}
        >
          <span slot="label">{group.name}</span>
          <WppIconChevron slot="right" direction="right" />
        </TreeListItem>
      ))}

      {!!ungrouped.length && (
        <Flex direction="column" gap={2} data-testid="ungrouped-apps">
          {ungrouped.map(appInstance => (
            <Fragment key={appInstance.id}>{renderApp(appInstance)}</Fragment>
          ))}
        </Flex>
      )}

      {!appInstances.length && emptyState}
      {action}
    </Flex>
  )
}
