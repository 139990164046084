import { MayBeNull } from '@wpp-open/core'

import { IdentityProvider } from 'types/auth/identityProvider'
import { TenantIdp, TenantIdpAlias } from 'types/tenants/tenant'
import { EVENTS } from 'utils/events'

export const getTenantFullIDPS = (
  defaultIdps?: TenantIdp[],
  tenantIdps?: TenantIdp[],
): { idps: TenantIdp[]; email: MayBeNull<TenantIdp>; signUp: MayBeNull<TenantIdp> } => {
  if (!defaultIdps || !tenantIdps) {
    return { idps: [], email: null, signUp: null }
  }

  const intersected = defaultIdps
    .map(idp => {
      const currentIdp = tenantIdps.find(x => x.alias.toLocaleLowerCase() === idp.alias.toLocaleLowerCase())

      // TODO Little hack while magic link is not implemented
      if (currentIdp?.alias === TenantIdpAlias.MagicLink) {
        return null
      }

      if (currentIdp) {
        return {
          ...currentIdp,
          logo: currentIdp.logo ? currentIdp.logo : idp.logo,
        }
      }
    })
    .filter(idp => !!idp)
  const availableIdps = (tenantIdps.length ? intersected : defaultIdps).filter(idp => idp!.enabled)
  return {
    idps: availableIdps
      .map(
        idp =>
          ({
            ...idp,
            legacyIDP: IdentityProvider[idp!.alias as keyof typeof IdentityProvider],
          }) as TenantIdp,
      )
      .filter(idp => idp.alias !== TenantIdpAlias.Email && idp.alias !== TenantIdpAlias.SignUp),
    email: availableIdps.find(idp => idp!.alias === TenantIdpAlias.Email) || null,
    signUp: availableIdps.find(idp => idp!.alias === TenantIdpAlias.SignUp) || null,
  }
}

export const getIdpsPayload = (alias: TenantIdpAlias) => {
  switch (alias) {
    case TenantIdpAlias.Okta:
      return EVENTS.TENANT_LOGIN_PAGE.ACTIONS.OKTA
    case TenantIdpAlias.GroupM:
      return EVENTS.TENANT_LOGIN_PAGE.ACTIONS.GROUP_M
    case TenantIdpAlias.MagicLink:
      return EVENTS.TENANT_LOGIN_PAGE.ACTIONS.MAGIC_LINK
    default:
      return null
  }
}
