import { facadeApi } from 'api'

interface Params {
  tenantId: string
  requestReason: string
}

export const createAccessRequestApi = ({ tenantId, requestReason }: Params) =>
  facadeApi.post('/access-requests', {
    tenantId,
    requestReason,
  })
