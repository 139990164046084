import 'components/zendesk/ZendeskWidget.scss'

import { AnalyticsActionType } from '@wpp-open/core'
import { useMemo, useState } from 'react'
import { useEvent, useLockBodyScroll } from 'react-use'

import { Script } from 'components/common/script/Script'
import { zendeskAPI, handleCloseWidget, getWidgetColor } from 'components/zendesk/index'
import { ZendeskWidgetColor } from 'components/zendesk/utils/types'
import { environment } from 'environment'
import { useStableCallback } from 'hooks/useStableCallback'
import { useOsSupportService } from 'providers/osSupportService/OsSupportServiceContext'
import { usePublicData } from 'providers/publicData/PublicDataContext'
import { SupportService } from 'types/tenants/tenant'
import { trackAnalytics } from 'utils/analytics'

const ZE_DEFAULT_LANGUAGE = 'en-gb'

const ZendeskScriptSrc = `https://static.zdassets.com/ekr/snippet.js?key=${environment.ZENDESK_KEY}`

export const ZendeskWidget = () => {
  const { onActiveStateChange } = useOsSupportService(SupportService.Zendesk)
  const { resolvedTheme } = usePublicData()
  const [isOpen, setIsOpen] = useState(false)

  const widgetColor: ZendeskWidgetColor = useMemo(() => getWidgetColor(resolvedTheme), [resolvedTheme])

  useEvent(
    'click',
    (e: any) => {
      if (e.target.id === 'webWidget') {
        handleCloseWidget()
      }
    },
    isOpen ? window : null,
  )

  useLockBodyScroll(isOpen)

  const onOpenStable = useStableCallback(() => {
    const settings = zendeskAPI.getSettings()

    zendeskAPI.updateSettings({
      ...settings,
      webWidget: {
        ...settings.webWidget,
        color: widgetColor,
      },
    })

    setIsOpen(true)

    trackAnalytics({
      type: AnalyticsActionType.action,
      payload: 'Open Support Widget',
    })
  })

  const onCloseStable = useStableCallback(() => {
    zendeskAPI.reset()
    zendeskAPI.hide()
    setIsOpen(false)
  })

  return (
    <Script
      // id="ze-snippet" is required for zendesk script otherwise it won't work
      id="ze-snippet"
      async
      src={ZendeskScriptSrc}
      onLoad={() => {
        zendeskAPI.hide()
        zendeskAPI.setLocale(ZE_DEFAULT_LANGUAGE)
        zendeskAPI.onOpen(onOpenStable)
        zendeskAPI.onClose(onCloseStable)
        onActiveStateChange(true)
      }}
      onError={() => {
        onActiveStateChange(false)
      }}
    />
  )
}
