import { WppActionButton, WppIconChevron, WppMenuContext, WppSkeleton } from '@platform-ui-kit/components-library-react'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAppInstancesApi } from 'api/apps/queries/useAppInstancesApi'
import { Flex } from 'components/common/flex/Flex'
import { AppInstancesDefaults } from 'constants/appInstances'
import { PageSize } from 'constants/pageSize'
import { BreadcrumbsContainer } from 'layout/header/breadcrumbs/common/container/BreadcrumbsContainer'
import { BreadcrumbItem } from 'layout/header/breadcrumbs/common/item/BreadcrumbItem'
import styles from 'layout/header/breadcrumbs/hierarchy/HierarchyBreadcrumbs.module.scss'
import { HierarchyBreadcrumbsMenu } from 'layout/header/breadcrumbs/hierarchy/menu/HierarchyBreadcrumbsMenu'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { useOsState } from 'providers/osState/OsStateProvider'
import { AppInstanceAssignmentType } from 'types/apps/appInstances'
import { BreadcrumbItemHierarchy, BreadcrumbItemHierarchyType } from 'types/osState/breadcrumbs'
import { mapAppInstanceToApp } from 'utils/appInstances'

interface Props {
  items: BreadcrumbItemHierarchy[]
}

export const HierarchyBreadcrumbs = ({ items }: Props) => {
  const navigate = useNavigate()
  const { navigationTree } = useCurrentTenantData()
  const { openNavigation, appData } = useOsState()
  const [isOpen, setIsOpen] = useState(false)

  const { activeWorkspaceId, appInstance: activeAppInstance } = appData
  const { rootId } = navigationTree

  const { data: appInstances, isLoading } = useAppInstancesApi({
    params: {
      assignmentId: activeWorkspaceId || rootId,
      assignmentType: appData.isCustomNavigation
        ? AppInstanceAssignmentType.Custom
        : AppInstanceAssignmentType.Workspace,
      itemsPerPage: PageSize.All,
    },
    staleTime: AppInstancesDefaults.BreadcrumbsStaleTime,
    enabled: isOpen,
  })

  const apps = useMemo(() => {
    let filteredAppInstances = appInstances

    // Apps in custom menu are filtered by active group
    if (appData.isCustomNavigation) {
      const activeGroupId = activeAppInstance?.group?.id

      filteredAppInstances = appInstances.filter(appInstance => {
        const groupId = appInstance.group?.id

        return (!activeGroupId && !groupId) || activeGroupId === groupId
      })
    }

    const visibleFilteredAppInstances = filteredAppInstances.filter(app => app.flags.visible)

    return visibleFilteredAppInstances.map(appInstance => mapAppInstanceToApp(appInstance))
  }, [activeAppInstance?.group?.id, appData.isCustomNavigation, appInstances])

  const collapsibleItems = useMemo(() => items.slice(0, -1), [items])
  const lastItem = items.at(-1)

  const handleItemClick = ({ type, id, link }: BreadcrumbItemHierarchy) => {
    if (link) {
      navigate(link)
    } else if (type === BreadcrumbItemHierarchyType.Hierarchy) {
      openNavigation(id)
    }
  }

  return (
    <BreadcrumbsContainer items={collapsibleItems} onItemClick={handleItemClick}>
      {!!lastItem && (
        <WppMenuContext
          className={styles.appsMenuWrapper}
          externalClass={styles.appsMenu}
          dropdownConfig={{
            offset: [-18, 0], // Align with apps column
            onShow: () => setIsOpen(true),
            onHidden: () => setIsOpen(false),
          }}
          data-testid="breadcrumb-dropdown"
        >
          <BreadcrumbItem slot="trigger-element" label={lastItem.label}>
            <WppActionButton variant="secondary">
              <WppIconChevron slot="icon-start" direction="down" />
            </WppActionButton>
          </BreadcrumbItem>

          <Flex direction="column">
            {isLoading ? (
              <Flex className={styles.skeletonsWrapper} direction="column" gap={16}>
                <WppSkeleton height={20} />
                <WppSkeleton height={20} />
                <WppSkeleton height={20} />
              </Flex>
            ) : (
              <>{isOpen ? <HierarchyBreadcrumbsMenu apps={apps} /> : null}</>
            )}
          </Flex>
        </WppMenuContext>
      )}
    </BreadcrumbsContainer>
  )
}
