import { createContext, useContext } from 'react'

import { TenantDefault, KnownHostInfo } from 'types/tenants/tenant'

export const OsRootContext = createContext<{
  hostInfo: KnownHostInfo
  defaultTenant: TenantDefault
}>(null!)

export const useOsRoot = () => useContext(OsRootContext)
