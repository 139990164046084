import { Frame, SerializedNodes } from '@craftjs/core'
import { PageBuilder, defaultWidgets } from '@wpp-open/content-builder'
import { useCallback } from 'react'

import { useFetchPageBuilderAssetApi } from 'api/pageBuilder/queryFetchers/useFetchLeanAppAssetApi'
import { useUploadWidgetsAssetApi } from 'api/widgets/mutations/useUploadWidgetsAssetApi'
import styles from 'components/contentBuilderView/PageBuilderView.module.scss'
import { usePublicData } from 'providers/publicData/PublicDataContext'
import { useToast } from 'providers/toast/ToastProvider'
import { PageBuilderContent } from 'types/apps/leanApps'

interface Props {
  content: PageBuilderContent
}
export const PageBuilderView = ({ content }: Props) => {
  const loadAssetLoader = useFetchPageBuilderAssetApi()
  const loadAsset = useCallback(
    ({ assetId }: { assetId: string }) => loadAssetLoader({ assetId }).then(response => response.data),
    [loadAssetLoader],
  )

  const { mutateAsync: uploadAsset } = useUploadWidgetsAssetApi()
  const { enqueueToast } = useToast()
  const { resolvedTheme: theme } = usePublicData()

  return (
    <div className={styles.root}>
      <PageBuilder
        resolver={defaultWidgets}
        enabled={false}
        dependencies={{ loadAsset, uploadAsset, enqueueToast, theme }}
      >
        <div className={styles.container}>
          <Frame data={content as SerializedNodes} />
        </div>
      </PageBuilder>
    </div>
  )
}
