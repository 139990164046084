import { Environment, OsDeploymentEnvironment } from 'environment/common'

// These are replaced during CI *deployment* phase
export const prodEnvironment: Environment = {
  ENV: 'WPP_TEMPLATE_ENV' as OsDeploymentEnvironment,
  LEGACY_BOX_ENV: 'WPP_TEMPLATE_LEGACY_BOX_ENV',
  KEYCLOAK_AUTHORITY: 'WPP_TEMPLATE_KEYCLOAK_AUTHORITY',
  KEYCLOAK_CLIENT_ID: 'WPP_TEMPLATE_KEYCLOAK_CLIENT_ID',
  GENERIC_TENANT_URL: 'WPP_TEMPLATE_GENERIC_TENANT_URL',
  NEWS_BUNDLE_URL: 'WPP_TEMPLATE_NEWS_BUNDLE_URL',
  MARKETPLACE_BUNDLE_URL: 'WPP_TEMPLATE_MARKETPLACE_BUNDLE_URL',
  ORCHESTRATION_BUNDLE_URL: 'WPP_TEMPLATE_ORCHESTRATION_BUNDLE_URL',
  DEVHUB_BUNDLE_URL: 'WPP_TEMPLATE_DEVHUB_BUNDLE_URL',
  THEME_BUILDER_BUNDLE_URL: 'WPP_TEMPLATE_THEME_BUILDER_BUNDLE_URL',
  SEGMENT_ANALYTICS_KEY: 'WPP_TEMPLATE_SEGMENT_ANALYTICS_KEY',
  INTERCOM_APP_ID: 'WPP_TEMPLATE_INTERCOM_APP_ID',
  INTERCOM_API_BASE: 'WPP_TEMPLATE_INTERCOM_API_BASE',
  RECENT_WORK_API_URL: 'WPP_TEMPLATE_RECENT_WORK_API_URL',
  NEWS_API_URL: 'WPP_TEMPLATE_NEWS_API_URL',
  ZENDESK_KEY: 'WPP_TEMPLATE_ZENDESK_KEY',
  AI_ASSISTANT_BUNDLE_URL: 'WPP_TEMPLATE_AI_ASSISTANT_BUNDLE_URL',
}
