import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useRejectHierarchyAccessRequestApi } from 'api/accessRequests/mutations/useRejectHierarchyAccessRequestApi'
import { Flex } from 'components/common/flex/Flex'
import { FormTextareaInput } from 'components/form/formTextareaInput/FormTextareaInput'
import { Modal } from 'components/surface/modal/Modal'
import { useForm } from 'hooks/form/useForm'
import {
  CHARACTERS_LIMIT,
  useValidationSchema,
} from 'pages/admin/requests/hierarchyAccessRequests/rejectHierarchyAccessRequestModal/utils'
import { UsersInfoCard } from 'pages/admin/requests/hierarchyAccessRequests/usersInfoCard/UsersInfoCard'
import {
  handleReloadHierarchyAccessRequestsTables,
  isProcessedRequest,
} from 'pages/admin/requests/hierarchyAccessRequests/utils'
import { useToast } from 'providers/toast/ToastProvider'
import { HierarchyAccessRequest } from 'types/requests/requests'
import { capitalize } from 'utils/common'
import { NiceModalWrappedProps, createNiceModal } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  request: HierarchyAccessRequest
  getAttachmentUrlByKey: (key?: string) => string
}

const RejectHierarchyAccessRequestModal = ({
  request,
  getAttachmentUrlByKey,
  isOpen,
  onClose,
  onCloseComplete,
  id,
}: Props) => {
  const { enqueueToast } = useToast()
  const { t } = useTranslation()

  const form = useForm({
    validationSchema: useValidationSchema(),
    defaultValues: { rejectReason: '' },
  })

  const { mutateAsync: handleRejectHierarchyAccessRequest } = useRejectHierarchyAccessRequestApi()

  const { handleSubmit, formState } = form
  const { isValid, isSubmitting } = formState

  const onSubmit = handleSubmit(async ({ rejectReason }) => {
    try {
      await handleRejectHierarchyAccessRequest({
        requestId: request.id,
        rejectReason,
      })

      handleReloadHierarchyAccessRequestsTables()

      enqueueToast({
        type: 'success',
        message: capitalize(
          t('os.requests.hierarchy_access_requests.actions_modal.toasts.reject_success', {
            requestType: t('os.requests.request_types.hierarchy_access'),
          }),
        ),
      })
    } catch (error) {
      if (isProcessedRequest(error)) {
        enqueueToast({
          message: capitalize(t('os.common.errors.processed_request')),
          type: 'error',
        })
      } else {
        enqueueToast({
          message: capitalize(t('os.common.errors.general')),
          type: 'error',
        })
      }
    } finally {
      onClose()
    }
  })

  return (
    <FormProvider {...form}>
      <Modal
        open={isOpen}
        onWppModalClose={onClose}
        onWppModalCloseComplete={onCloseComplete}
        size="m"
        data-testid={id}
        formConfig={{ onSubmit }}
      >
        <WppTypography type="xl-heading" slot="header">
          {t('os.requests.hierarchy_access_requests.actions_modal.reject_hierarchy.title')}
        </WppTypography>
        <Flex direction="column" slot="body" gap={16}>
          <UsersInfoCard request={request} getAttachmentUrlByKey={getAttachmentUrlByKey} />

          <FormTextareaInput
            required
            labelConfig={{
              text: t('os.requests.hierarchy_access_requests.actions_modal.reject_hierarchy.reject_reason.label'),
            }}
            charactersLimit={CHARACTERS_LIMIT}
            warningThreshold={CHARACTERS_LIMIT}
            name="rejectReason"
            placeholder={t(
              'os.requests.hierarchy_access_requests.actions_modal.reject_hierarchy.reject_reason.placeholder',
            )}
          />
        </Flex>

        <Flex slot="actions" gap={12} justify="end">
          <WppButton variant="secondary" onClick={onClose} data-testid="cancel">
            {t('os.common.cancel')}
          </WppButton>
          <WppButton variant="primary" type="submit" loading={isSubmitting} disabled={!isValid} data-testid="apply">
            {capitalize(t('os.requests.actions.reject'))}
          </WppButton>
        </Flex>
      </Modal>
    </FormProvider>
  )
}

export const { showModal: showRejectHierarchyAccessRequestModal } = createNiceModal(
  RejectHierarchyAccessRequestModal,
  'reject-hierarchy-access-request-modal',
)
