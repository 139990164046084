import {
  DataIsNotAvailableErrorState,
  ForbiddenPageErrorState,
  ForbiddenOSAccessErrorState,
  NotFoundErrorState,
  CriticalErrorState,
  OsIsNotAvailableErrorState,
} from 'components/renderError/errorState'
import { RenderError, RenderErrorType } from 'components/renderError/utils'

interface Props {
  error: RenderError
  resetError?: () => void
}

export const RenderErrorBoundaryView = ({ error, resetError }: Props) => {
  const { cause } = error

  return (
    <>
      {cause === RenderErrorType.OsIsNotAvailable && <OsIsNotAvailableErrorState />}
      {cause === RenderErrorType.DataIsNotAvailable && <DataIsNotAvailableErrorState />}
      {cause === RenderErrorType.ForbiddenPage && <ForbiddenPageErrorState resetError={resetError} />}
      {cause === RenderErrorType.ForbiddenOSAccess && <ForbiddenOSAccessErrorState resetError={resetError} />}
      {cause === RenderErrorType.NotFound && <NotFoundErrorState resetError={resetError} />}
      {cause === RenderErrorType.CriticalError && <CriticalErrorState resetError={resetError} />}
    </>
  )
}
