import { createApi } from 'api/common/createApi'
import { environment } from 'environment'

export const userDetailsApi = createApi({
  baseURL: '/_apps/user-details/api',
})

export const workspacesApi = createApi({
  baseURL: '/_apps/os-workspaces/api',
})

export const appsApi = createApi({
  baseURL: '/_apps/os-apps-backend/api',
})

export const facadeApi = createApi({
  baseURL: '/api',
})

export const recentWorkApi = createApi({
  baseURL: environment.RECENT_WORK_API_URL,
})

export const newsApi = createApi({
  baseURL: environment.NEWS_API_URL,
})
