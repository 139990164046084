import { PropsWithChildren, useEffect } from 'react'

import { useDefaultTenantApi } from 'api/tenant/queries/useDefaultTenantApi'
import { useHostInfoApi } from 'api/tenant/queries/useHostInfoApi'
import { OsIsNotAvailableError } from 'components/renderError'
import { GENERIC_TENANT_DEV_ROUTE } from 'constants/routes'
import { environment } from 'environment'
import { OsRootContext } from 'providers/osRoot/OsRootContext'
import { HostType } from 'types/tenants/tenant'

type Props = PropsWithChildren<{}>

export const OsRootProvider = ({ children }: Props) => {
  const { data: hostInfo, isLoading: isHostInfoLoading, isError: isHostInfoError } = useHostInfoApi()

  const {
    data: defaultTenant,
    isLoading: isDefaultTenantLoading,
    isError: isDefaultTenantError,
  } = useDefaultTenantApi()

  const isUnknownTenant = hostInfo?.type === HostType.UNKNOWN

  useEffect(() => {
    if (isUnknownTenant) {
      if (process.env.PROD) {
        window.location.assign(environment.GENERIC_TENANT_URL)
      }

      if (process.env.DEV) {
        console.error('Unknown tenant, use a different one for development')
        window.location.assign(GENERIC_TENANT_DEV_ROUTE)
      }
    }
  }, [isUnknownTenant])

  if (isHostInfoLoading || isDefaultTenantLoading || isUnknownTenant) {
    return null
  }

  if (isHostInfoError || isDefaultTenantError) {
    return <OsIsNotAvailableError />
  }

  return (
    <OsRootContext.Provider
      value={{
        hostInfo: hostInfo!,
        defaultTenant: defaultTenant!,
      }}
    >
      {children}
    </OsRootContext.Provider>
  )
}
