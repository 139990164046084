import { WppListItem } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { BreadcrumbMenuAppItem } from 'layout/header/breadcrumbs/common/menuAppItem/BreadcrumbMenuAppItem'
import { useOsState } from 'providers/osState/OsStateProvider'
import { AppInstanceApp } from 'utils/appInstances'

interface Props {
  apps: AppInstanceApp[]
}

export const HierarchyBreadcrumbsMenu = ({ apps }: Props) => {
  const { t } = useTranslation()
  const { appData } = useOsState()
  const { app } = appData

  const isListEmpty = !apps.length

  return (
    <Flex gap={4} direction="column">
      {apps.map(hierarchyApp => {
        const isChecked = hierarchyApp.stableId === app?.stableId

        return <BreadcrumbMenuAppItem key={hierarchyApp.stableId} isChecked={isChecked} app={hierarchyApp} />
      })}

      {isListEmpty && (
        <WppListItem disabled>
          <span slot="label">{t('os.header.breadcrumbs.no_apps')}</span>
        </WppListItem>
      )}
    </Flex>
  )
}
