import { MicroAppType } from 'types/apps/microApps'
import { NoCodeAppType } from 'types/apps/noCodeApps'
import {
  AppDataType,
  ExternalMicroAppData,
  InvalidAppData,
  ExternalLegacyMicroAppData,
  LocalLegacyMicroAppData,
  LoadingAppData,
  LocalMicroAppData,
  NoCodeAppData,
  SystemMicroAppData,
} from 'types/osState/appDataResolved'

export const getLoadingAppData = (): LoadingAppData => ({
  type: AppDataType.Loading,
  app: null,
  currentBaseUrl: '',
  activeWorkspaceId: null,
  appInstance: null,
  project: null,
  projectCanvas: null,
  projectPhase: null,
  projectItem: null,
  additionalContext: null,
  isCustomNavigation: false,
})

export const getInvalidAppData = (): InvalidAppData => ({
  type: AppDataType.Invalid,
  app: null,
  currentBaseUrl: '',
  activeWorkspaceId: null,
  appInstance: null,
  project: null,
  projectCanvas: null,
  projectPhase: null,
  projectItem: null,
  additionalContext: null,
  isCustomNavigation: false,
})

export const getLocalMicroAppData = ({
  app,
  currentBaseUrl,
  activeWorkspaceId,
}: Pick<LocalMicroAppData, 'app' | 'currentBaseUrl' | 'activeWorkspaceId'>): LocalMicroAppData => ({
  type: AppDataType.LocalMicroApp,
  app,
  currentBaseUrl,
  activeWorkspaceId,
  appInstance: null,
  project: null,
  projectCanvas: null,
  projectPhase: null,
  projectItem: null,
  additionalContext: null,
  isCustomNavigation: false,
})

export const getLegacyLocalMicroAppData = ({
  app,
  currentBaseUrl,
  activeWorkspaceId,
}: Pick<LocalLegacyMicroAppData, 'app' | 'currentBaseUrl' | 'activeWorkspaceId'>): LocalLegacyMicroAppData => ({
  type: AppDataType.LocalLegacyMicroApp,
  app,
  currentBaseUrl,
  activeWorkspaceId,
  appInstance: null,
  project: null,
  projectCanvas: null,
  projectPhase: null,
  projectItem: null,
  additionalContext: null,
  isCustomNavigation: false,
})

export const getSystemMicroAppData = ({ app }: Pick<SystemMicroAppData, 'app'>): SystemMicroAppData => ({
  type: AppDataType.SystemMicroApp,
  app,
  currentBaseUrl: app.osRoute,
  activeWorkspaceId: null,
  appInstance: null,
  project: null,
  projectCanvas: null,
  projectPhase: null,
  projectItem: null,
  additionalContext: null,
  isCustomNavigation: false,
})

export type AppInstanceAppData = ExternalMicroAppData | ExternalLegacyMicroAppData | NoCodeAppData

export const getAppInstanceAppData = ({
  app,
  ...context
}: Pick<
  AppInstanceAppData,
  | 'app'
  | 'currentBaseUrl'
  | 'activeWorkspaceId'
  | 'appInstance'
  | 'project'
  | 'projectCanvas'
  | 'projectPhase'
  | 'projectItem'
  | 'additionalContext'
  | 'isCustomNavigation'
>): AppInstanceAppData => {
  const { type } = app

  if (type === MicroAppType.Native) {
    return {
      type: AppDataType.ExternalMicroApp,
      app,
      ...context,
    }
  }

  if (type === MicroAppType.Legacy) {
    return {
      type: AppDataType.ExternalLegacyMicroApp,
      app,
      ...context,
    }
  }

  if (Object.values(NoCodeAppType).includes(type)) {
    return {
      type: AppDataType.NoCodeApp,
      app,
      ...context,
    }
  }

  throw new Error('Unsupported App Instance app type')
}
