import clsx from 'clsx'
import { useCss } from 'react-use'

import styles from 'layout/authLayout/AuthLayout.module.scss'
import { useOsRoot } from 'providers/osRoot/OsRootContext'

export const AuthLayout = ({ className, children, ...rest }: JSX.IntrinsicElements['div']) => {
  const { hostInfo, defaultTenant } = useOsRoot()

  const tenantBackgroundUrl = hostInfo?.currentTenant?.loginBackground?.url || defaultTenant.loginBackground?.url

  return (
    <div {...rest} className={clsx(styles.container, className)}>
      <div
        data-testid="login-preview"
        className={clsx(
          styles.background,
          useCss({
            backgroundImage: `url(${tenantBackgroundUrl})`,
          }),
        )}
      />
      <div className={styles.card}>{children}</div>
    </div>
  )
}
