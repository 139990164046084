import { WppActionButton, WppIconNavigationMenu } from '@platform-ui-kit/components-library-react'
import { AnalyticsActionType } from '@wpp-open/core'

import { NavigationMenuSidebar } from 'components/navigationMenu/navigationMenuSidebar/NavigationMenuSidebar'
import { useOsState } from 'providers/osState/OsStateProvider'
import { trackAnalytics } from 'utils/analytics'
import { EVENTS } from 'utils/events'

export const NavigationMenu = () => {
  const { isNavigationOpen, openNavigation } = useOsState()

  return (
    <div data-testid="navigation-menu">
      <WppActionButton
        variant="secondary"
        disabled={isNavigationOpen}
        onClick={() => {
          openNavigation()
          trackAnalytics({
            type: AnalyticsActionType.action,
            payload: EVENTS.HAMBURGER_MENU.ACTIONS.ACTION_HUMBURGER_MENU,
          })
        }}
        data-testid="navigation-menu-button"
      >
        <WppIconNavigationMenu slot="icon-start" />
      </WppActionButton>

      <NavigationMenuSidebar />
    </div>
  )
}
