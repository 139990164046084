import 'legacy/styles/wmNgComponents/angular-material.scss'
import 'legacy/styles/wmNgComponents/theme.scss'

import { HierarchyLevelType, MayBeNull, PermissionsRecord } from '@wpp-open/core'
import { useEffect, useMemo } from 'react'

import { useUserPermissionsApi } from 'api/alphaZulu/queries/useUserPermissionsApi'
import { useOtherWorkspacesApi } from 'api/workspaces/queries/useOtherWorkspacesApi'
import { useWorkspaceAgenciesApi } from 'api/workspaces/queries/useWorkspaceAgenciesApi'
import { useWorkspaceApi } from 'api/workspaces/queries/useWorkspaceApi'
import { DataIsNotAvailableError } from 'components/renderError'
import { LoadingPage } from 'layout/loadingPage/LoadingPage'
import { LegacyMicroApp } from 'legacy/LegacyMicroApp'
import { getThemeStyles } from 'legacy/styles/osTheming'
import { legacyThemesList } from 'legacy/styles/osTheming/themes'
import {
  AccountPermissions,
  LegacyTenant,
  legacyTenants,
  LegacyWorkspace,
  WorkspaceType,
} from 'legacy/types/osWebRootApi'
import { LegacyState, legacyState } from 'legacy/utils/state'
import { getCurrentTenantCode } from 'legacy/utils/tenant'
import { useAuth } from 'providers/auth/AuthContext'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { useOtherTenantsAndUserData } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'
import { WorkspaceAgency } from 'types/agencies/workspaceAgency'
import { LegacyMicroAppData } from 'types/osState/appDataResolved'
import { Workspace } from 'types/workspaces/workspace'
import { resolveActiveHierarchy } from 'utils/workspace'

interface InnerProps {
  permissions: PermissionsRecord[]
  appData: LegacyMicroAppData
  agencies: WorkspaceAgency[]
  markets: MayBeNull<Workspace[]>
  clientWorkspace: MayBeNull<Workspace>
  marketWorkspace: MayBeNull<Workspace>
  brandWorkspace: MayBeNull<Workspace>
}

const LegacyMicroAppContainerInner = ({
  permissions,
  clientWorkspace,
  marketWorkspace,
  brandWorkspace,
  appData,
  agencies,
  markets,
}: InnerProps) => {
  const { jwt } = useAuth()
  const { userDetails } = useOtherTenantsAndUserData()
  const { currentTenant } = useCurrentTenantData()
  const { currentBaseUrl } = appData

  const selectedWorkspace = useMemo(() => {
    if (clientWorkspace && marketWorkspace && brandWorkspace) {
      return {
        ...brandWorkspace,
        parent: {
          ...marketWorkspace,
          parent: { ...clientWorkspace },
        },
      } as LegacyWorkspace
    }

    return null
  }, [clientWorkspace, marketWorkspace, brandWorkspace])

  const legacyPermissions: MayBeNull<AccountPermissions[]> = useMemo(() => {
    if (permissions && markets) {
      return permissions.map(permission => {
        const workspace = (markets as LegacyWorkspace[]).find(market => market.azId === permission.account_id)

        return { ...permission, workspace }
      })
    }

    return permissions || []
  }, [permissions, markets])

  const mappedLegacyTenants: LegacyTenant[] = useMemo(
    () =>
      legacyTenants.map(legacyTenant => {
        const foundAgency = agencies.find(agency => agency.code === legacyTenant.code)

        return {
          ...legacyTenant,
          id: foundAgency?.azId,
          organisationsId: foundAgency?.organisationsId,
          applicationsId: foundAgency?.applicationsId,
        }
      }),
    [agencies],
  )

  const currentMappedLegacyTenant = useMemo(
    () =>
      mappedLegacyTenants.find(
        legacyTenant => legacyTenant.code === getCurrentTenantCode({ tenant: currentTenant, agencies }),
      ) || mappedLegacyTenants[0],
    [currentTenant, agencies, mappedLegacyTenants],
  )

  // Always update sources with next values.
  // Distinct values will be handled by the LegacyState.
  LegacyState.currentOrganisationId = currentTenant.azMeta.organizationsId
  legacyState.rawSources.authJwt$.next(jwt)
  legacyState.rawSources.baseUrl$.next(currentBaseUrl)
  legacyState.rawSources.user$.next(userDetails)
  legacyState.rawSources.workspace$.next(selectedWorkspace)
  legacyState.rawSources.permissions$.next(legacyPermissions)
  legacyState.rawSources.currentAgency$.next(currentMappedLegacyTenant)
  legacyState.rawSources.agencies$.next(mappedLegacyTenants)

  const currentTheme = legacyThemesList.find(legacyTheme => legacyTheme.code === currentMappedLegacyTenant.code)
  const themeStyles = useMemo(() => (currentTheme ? getThemeStyles(currentTheme) : {}), [currentTheme])

  // These styles are used in modal containers outside the microapp wrapper element,
  // so they have to be applied globally
  useEffect(() => {
    Object.entries(themeStyles).map(([key, value]) => {
      document.documentElement.style.setProperty(key, value)
    })

    return () => {
      Object.keys(themeStyles).map(key => {
        document.documentElement.style.removeProperty(key)
      })
    }
  }, [themeStyles])

  useEffect(() => {
    document.documentElement.classList.add(
      'angular-material',
      'default-theme',
      `${currentMappedLegacyTenant.defaultTheme}`,
    )

    return () => {
      document.documentElement.classList.remove(
        'angular-material',
        'default-theme',
        `${currentMappedLegacyTenant.defaultTheme}`,
      )
    }
  }, [currentMappedLegacyTenant.defaultTheme])

  return <LegacyMicroApp appData={appData} selectedWorkspace={selectedWorkspace} />
}

interface Props {
  appData: LegacyMicroAppData
}

export const LegacyMicroAppContainer = ({ appData }: Props) => {
  const { activeWorkspaceId } = appData
  const { currentTenant, navigationTreeWithHiddenLevel } = useCurrentTenantData()
  const { user } = useAuth()

  const activeHierarchy = useMemo(
    () =>
      resolveActiveHierarchy({
        validHierarchyId: activeWorkspaceId,
        navigationTree: navigationTreeWithHiddenLevel,
      }),
    [activeWorkspaceId, navigationTreeWithHiddenLevel],
  )

  const resolvedWorkspaceNodes = activeHierarchy.map(nodeAzId => navigationTreeWithHiddenLevel.mapping[nodeAzId])
  const clientId = resolvedWorkspaceNodes.find(node => node.type === HierarchyLevelType.Client)?.azId
  const marketId = resolvedWorkspaceNodes.find(node => node.type === HierarchyLevelType.Market)?.azId
  const brandId = resolvedWorkspaceNodes.find(node => node.type === HierarchyLevelType.Brand)?.azId

  const {
    isLoading: isPermissionsLoading,
    data: permissions,
    isError: isPermissionsError,
  } = useUserPermissionsApi({
    params: {
      email: user.email,
      accountId: currentTenant.azMeta.organizationsId,
      depth: 3,
    },
  })

  const { isLoading: isAgenciesLoading, data: agencies, isError: isAgenciesError } = useWorkspaceAgenciesApi()

  const {
    isLoading: isClientWorkspaceLoading,
    data: clientWorkspace,
    isError: isClientWorkspaceError,
  } = useWorkspaceApi({
    params: {
      workspaceAzId: clientId!,
    },
    enabled: !!clientId,
  })

  const {
    isLoading: isMarketWorkspaceLoading,
    data: marketWorkspace,
    isError: isMarketWorkspaceError,
  } = useWorkspaceApi({
    params: {
      workspaceAzId: marketId!,
    },
    enabled: !!marketId,
  })

  const {
    isLoading: isBrandWorkspaceLoading,
    data: brandWorkspace,
    isError: isBrandWorkspaceError,
  } = useWorkspaceApi({
    params: {
      workspaceAzId: brandId!,
    },
    enabled: !!brandId,
  })

  const {
    isLoading: isMarketsLoading,
    data: markets,
    isError: isMarketsError,
  } = useOtherWorkspacesApi({
    params: {
      azId: currentTenant.azId,
      type: WorkspaceType.Market,
    },
  })

  const isLoading =
    isPermissionsLoading ||
    isAgenciesLoading ||
    isClientWorkspaceLoading ||
    isMarketWorkspaceLoading ||
    isBrandWorkspaceLoading ||
    isMarketsLoading

  const isError =
    isPermissionsError ||
    isAgenciesError ||
    isClientWorkspaceError ||
    isMarketWorkspaceError ||
    isBrandWorkspaceError ||
    isMarketsError

  if (isLoading) {
    return <LoadingPage />
  }

  if (isError) {
    return <DataIsNotAvailableError />
  }

  return (
    <LegacyMicroAppContainerInner
      permissions={permissions}
      appData={appData}
      agencies={agencies!}
      markets={markets}
      clientWorkspace={clientWorkspace}
      marketWorkspace={marketWorkspace}
      brandWorkspace={brandWorkspace}
    />
  )
}
