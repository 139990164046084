import { ThemeStyles } from '@platform-ui-kit/components-library'
import { UserDetails, ChildHierarchyLevelType } from '@wpp-open/core'
import { ZendeskSettings } from 'zendesk-widget-typings'

import { zendeskAPI } from 'components/zendesk'
import {
  ARCHITECT_APPS,
  ARCHITECT_MODULES,
  CONTACT_SUPPORT_LABEL,
  ISSUE_FIELD_VALUE,
  requestNewEntityPrefill,
  TICKET_TYPE_FIELD_VALUE,
  WIDGET_FIELD_ID,
  WIDGET_PAGE_CODES,
} from 'components/zendesk/utils/constants'
import { ZendeskWidgetColor } from 'components/zendesk/utils/types'
import { HeaderAppTag } from 'layout/header/utils'
import { LegacyAppWindowLibraryName } from 'legacy/types/apps'
import { SystemAppCode } from 'types/apps/microApps'
import { join } from 'utils/common'

const getProductFieldInitialValue = (appOrPageCode: string) => {
  if (ARCHITECT_APPS.includes(appOrPageCode as LegacyAppWindowLibraryName)) {
    switch (appOrPageCode as LegacyAppWindowLibraryName) {
      case LegacyAppWindowLibraryName.ProteusOnboarding:
        return ISSUE_FIELD_VALUE.PROTEUS_ONBOARDING
      default:
        return ISSUE_FIELD_VALUE.ARCHITECT
    }
  }

  switch (appOrPageCode) {
    case SystemAppCode.Marketplace:
      return ISSUE_FIELD_VALUE.MARKETPLACE
    default:
      return ISSUE_FIELD_VALUE.OS
  }
}

export const getWidgetColor = (theme: ThemeStyles): ZendeskWidgetColor => ({
  theme: theme.color.primary['100'],
  launcher: theme.color.primary['500'],
  launcherText: theme.color.grey['000'],
  button: theme.color.primary['500'],
  articleLinks: theme.color.primary['400'],
  resultLists: theme.color.primary['500'],
  header: theme.color.primary['500'],
})

interface WidgetSettings {
  appOrPageCode: HeaderAppTag | (typeof WIDGET_PAGE_CODES)[keyof typeof WIDGET_PAGE_CODES]
  entityType?: ChildHierarchyLevelType
  userDetails?: UserDetails
}

export const handleOpenWidget = ({ appOrPageCode, ...rest }: WidgetSettings) => {
  if (appOrPageCode !== WIDGET_PAGE_CODES.LOGIN) {
    zendeskAPI.prefill({
      [WIDGET_FIELD_ID.EMAIL]: { readOnly: true },
      [WIDGET_FIELD_ID.NAME]: { readOnly: true },
    })
  }

  window.zESettings = getWidgetSettings({ appOrPageCode, ...rest })

  zendeskAPI.open()
  zendeskAPI.show()
}

export const handleCloseWidget = () => {
  zendeskAPI.hide()
  zendeskAPI.close()
}

const getWidgetSettings = ({
  entityType,
  appOrPageCode,
  userDetails,
}: WidgetSettings): ZendeskSettings & { app: string } => {
  const isLoginPage = appOrPageCode === WIDGET_PAGE_CODES.LOGIN
  const isArchitectApp = ARCHITECT_APPS.includes(appOrPageCode as LegacyAppWindowLibraryName)

  const productFieldInitialValue = getProductFieldInitialValue(appOrPageCode)

  const architectModuleFieldInitialValue = ARCHITECT_MODULES[appOrPageCode as LegacyAppWindowLibraryName] || ''

  const ticketTypeFieldInitialValue = entityType
    ? requestNewEntityPrefill[entityType].ticketType
    : isLoginPage
      ? TICKET_TYPE_FIELD_VALUE.SUPPORT
      : ''

  return {
    app: appOrPageCode,
    webWidget: {
      ...(!isLoginPage && {
        helpCenter: {
          messageButton: { '*': CONTACT_SUPPORT_LABEL },
        },
      }),
      contactForm: {
        ...(!isLoginPage && { title: { '*': CONTACT_SUPPORT_LABEL } }),

        fields: [
          // User
          {
            id: WIDGET_FIELD_ID.EMAIL,
            prefill: { '*': userDetails?.email.toLowerCase() || '' },
          },
          {
            id: WIDGET_FIELD_ID.NAME,
            prefill: { '*': join([userDetails?.firstname, userDetails?.lastname], ' ') },
          },

          // Product
          {
            id: WIDGET_FIELD_ID.ISSUE,
            prefill: { '*': productFieldInitialValue, select: productFieldInitialValue },
          },

          // Architect module
          ...(isArchitectApp
            ? [
                {
                  id: WIDGET_FIELD_ID.ARCHITECT_MODULE,
                  prefill: {
                    '*': architectModuleFieldInitialValue,
                    select: architectModuleFieldInitialValue,
                  },
                },
              ]
            : []),

          // Ticket type
          {
            id: WIDGET_FIELD_ID.TICKET_TYPE,
            prefill: { '*': ticketTypeFieldInitialValue, select: ticketTypeFieldInitialValue },
          },

          // Request type
          ...(entityType
            ? [
                {
                  id: WIDGET_FIELD_ID.REQUEST_TYPE,
                  prefill: {
                    '*': requestNewEntityPrefill[entityType].requestType,
                    select: requestNewEntityPrefill[entityType].requestType,
                  },
                },
              ]
            : []),

          // Subject
          {
            id: WIDGET_FIELD_ID.SUBJECT,
            prefill: { '*': entityType ? requestNewEntityPrefill[entityType].subject : '' },
          },

          // Description
          {
            id: WIDGET_FIELD_ID.DESCRIPTION,
            prefill: { '*': entityType ? requestNewEntityPrefill[entityType].description : '' },
          },
        ],
      },
      // color: getWidgetColor(theme),
    },
  }
}
