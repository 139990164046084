import { useCallback, useState } from 'react'

import { useFetchGenerateUserDetailsDownloadUrlsApi } from 'api/attachments/queryFetchers/useFetchGenerateUserDetailsDownloadUrlsApi'
import { useStableCallback } from 'hooks/useStableCallback'

export const useUserDetailsAttachments = (
  params?: Parameters<typeof useFetchGenerateUserDetailsDownloadUrlsApi>[0],
) => {
  const handleGenerateUserDetailsDownloadUrls = useFetchGenerateUserDetailsDownloadUrlsApi(params)
  const [map, updateMap] = useState<Record<string, string>>({})

  const handleLoadAttachments = useCallback(
    async (keys: string[]) => {
      if (keys.length) {
        const { data } = await handleGenerateUserDetailsDownloadUrls({ keys })
        const portion = Object.fromEntries(data.map(({ key, signed_url }) => [key, signed_url]))

        updateMap(prev => ({
          ...prev,
          ...portion,
        }))
      }
    },
    [handleGenerateUserDetailsDownloadUrls],
  )

  const getAttachmentUrlByKey = useStableCallback((key?: string) => map[key || ''] || '')

  return { handleLoadAttachments, getAttachmentUrlByKey }
}
