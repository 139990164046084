import { lazy } from 'react'

const NotFoundErrorState = lazy(() => import('components/renderError/errorState/notFound/NotFoundErrorState'))

const ForbiddenPageErrorState = lazy(
  () => import('components/renderError/errorState/forbiddenPage/ForbiddenPageErrorState'),
)

const ForbiddenOSAccessErrorState = lazy(
  () => import('components/renderError/errorState/forbiddenOSAccess/ForbiddenOSAccessErrorState'),
)

const OsIsNotAvailableErrorState = lazy(
  () => import('components/renderError/errorState/osIsNotAvailable/OsIsNotAvailableErrorState'),
)

const DataIsNotAvailableErrorState = lazy(
  () => import('components/renderError/errorState/dataIsNotAvailable/DataIsNotAvailableErrorState'),
)

const CriticalErrorState = lazy(() => import('components/renderError/errorState/criticalError/CriticalErrorState'))

export {
  NotFoundErrorState,
  ForbiddenPageErrorState,
  ForbiddenOSAccessErrorState,
  OsIsNotAvailableErrorState,
  DataIsNotAvailableErrorState,
  CriticalErrorState,
}
