import { format } from 'date-fns'
import { useCallback } from 'react'

import { useOtherTenantsAndUserData } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'
import { DateFormatLong } from 'types/common/dates'

export const useDateLongFormat = () => {
  const {
    userDetails: { dateLocale },
  } = useOtherTenantsAndUserData()

  return {
    formatDate: useCallback(
      (dateToFormat: string): string => {
        const locale = DateFormatLong[dateLocale as keyof typeof DateFormatLong]
        return format(new Date(dateToFormat), locale)
      },
      [dateLocale],
    ),
  }
}
