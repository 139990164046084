import qs from 'qs'

import { facadeApi } from 'api'
import { PaginatedResponse } from 'api/common/types'
import { HierarchyAccessRequest } from 'types/requests/requests'

export type HierarchyAccessRequestsListResponse = PaginatedResponse<HierarchyAccessRequest>

export enum HierarchyAccessRequestStatus {
  PENDING = 'pending',
  REJECTED = 'rejected',
  APPROVED = 'approved',
}

interface Params {
  tenantId: string
  search?: string
  status: HierarchyAccessRequestStatus[]
  sort: string
  itemsPerPage: number
  page: number
}

export const fetchHierarchyAccessRequestsListApi = ({ tenantId, status, search, sort, itemsPerPage, page }: Params) =>
  facadeApi.get<HierarchyAccessRequestsListResponse>('/hierarchy-requests', {
    params: {
      page,
      itemsPerPage,
      sort,
      filter: {
        tenantId,
        status,
        ...(!!search && { search }),
      },
    },
    paramsSerializer: {
      serialize: params => qs.stringify(params, { arrayFormat: 'repeat', encode: true }),
    },
  })
