import { facadeApi } from 'api'
import { AssignRolePayload } from 'types/roles/tenantRole'

interface Payload {
  account_id: string
  user_id: string
  role_id: string
}

export type UpdateUsersRolesPayload = AssignRolePayload<Payload>

export const updateUsersRolesApi = (payload: UpdateUsersRolesPayload) => facadeApi.patch('/az/users/roles', payload)
