import { ChildHierarchyLevelType, HierarchyLevel, HierarchyLevelType, RootHierarchyLevelType, Tenant } from '../types'

export const getRootHierarchyLevel = (tenant: Tenant): HierarchyLevel<RootHierarchyLevelType> =>
  tenant.hierarchyLevels.find(
    (level: HierarchyLevel): level is HierarchyLevel<RootHierarchyLevelType> =>
      level.type === HierarchyLevelType.Tenant,
  )!

export const getChildHierarchyLevels = (tenant: Tenant): HierarchyLevel<ChildHierarchyLevelType>[] =>
  tenant.hierarchyLevels.filter(
    (level: HierarchyLevel): level is HierarchyLevel<ChildHierarchyLevelType> =>
      level.type !== HierarchyLevelType.Tenant,
  )

export const getVisibleHierarchyLevels = (tenant: Tenant): HierarchyLevel[] =>
  tenant.hierarchyLevels.filter((level, index) => !tenant.flags.isFirstLevelHidden || index !== 1)
