import { useEffect } from 'react'

import { useMicroAppsContext } from 'providers/microApps/MicroAppsContext'
import { NativeMicroAppData } from 'types/osState/appDataResolved'
import { getActiveWhen } from 'utils/apps'
import { registerApp } from 'utils/singleSpa'

interface Props {
  appData: NativeMicroAppData
}

export const MicroApp = ({ appData }: Props) => {
  const { getMicroAppCustomProps, setIsMicroAppActive } = useMicroAppsContext()

  const { app } = appData

  useEffect(() => {
    const { stableId, libraryType, bundleUrl, windowLibraryName } = app

    registerApp({
      stableId,
      libraryType,
      windowLibraryName,
      bundleUrl: bundleUrl,
      activeWhen: getActiveWhen(app),
      customProps: getMicroAppCustomProps({ stableId }),
    })
  }, [app, getMicroAppCustomProps])

  useEffect(() => {
    setIsMicroAppActive(true)

    return () => setIsMicroAppActive(false)
  }, [setIsMicroAppActive])

  return null
}
