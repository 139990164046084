import qs from 'qs'

import { facadeApi } from 'api'
import { PaginatedResponse } from 'api/common/types'
import { Feed } from 'types/feeds/feeds'

interface Params {
  page?: number
  itemsPerPage?: number
  tenantIds?: string[]
  categories?: string[]
  createdAt?: string
}

export type FeedsListResponse = PaginatedResponse<Feed>

export const fetchFeedsApi = ({ page, itemsPerPage, categories, createdAt, tenantIds }: Params) =>
  facadeApi.get<FeedsListResponse>('feeds', {
    params: {
      page,
      itemsPerPage,
      filter: {
        createdAt,
        ...(!!categories?.length && { categories: categories }),
        ...(!!tenantIds?.length && { tenantId: tenantIds }),
      },
    },
    paramsSerializer: {
      serialize: params => qs.stringify(params, { arrayFormat: 'repeat', encode: true }),
    },
  })
