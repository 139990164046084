import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { Modal } from 'components/surface/modal/Modal'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  headerText?: string
  bodyText?: string
  leaveButtonText?: string
  continueButtonText?: string
  onCancel: () => void
}

export const CancelChangesModal = ({
  isOpen,
  onClose,
  id,
  onCloseComplete,
  headerText,
  bodyText,
  leaveButtonText,
  continueButtonText,
  onCancel,
}: Props) => {
  const { t } = useTranslation()

  return (
    <Modal data-testid={id} open={isOpen} onWppModalClose={onClose} onWppModalCloseComplete={onCloseComplete}>
      <WppTypography slot="header" type="xl-heading">
        {headerText || t('os.admin.settings.terms.modal.leave_without_saving_header')}
      </WppTypography>

      <WppTypography slot="body" type="s-body">
        {bodyText || t('os.admin.settings.terms.modal.leave_without_saving_text')}
      </WppTypography>

      <Flex slot="actions" gap={12} justify="end">
        <WppButton
          onClick={() => {
            onClose()
            onCancel()
          }}
          variant="secondary"
          size="s"
          data-testid="cancel"
        >
          {leaveButtonText || t('os.common.leave_without_saving')}
        </WppButton>
        <WppButton onClick={onClose} size="s" variant="primary" data-testid="continue">
          {continueButtonText || t('os.common.continue_editing')}
        </WppButton>
      </Flex>
    </Modal>
  )
}

export const { showModal: showCancelChangesModal } = createNiceModal(CancelChangesModal, 'cancel-changes-modal')
