import { ComponentPropsWithoutRef } from 'react'
import { useRouteError } from 'react-router-dom'

import { RenderErrorBoundaryLayout } from 'components/renderError/boundary/renderErrorBoundaryLayout/RenderErrorBoundaryLayout'
import { RenderErrorBoundaryView } from 'components/renderError/boundary/RenderErrorBoundaryView'
import { RenderError, RenderErrorType } from 'components/renderError/utils'

type Props = Omit<ComponentPropsWithoutRef<typeof RenderErrorBoundaryLayout>, 'children'> & {
  catchErrors?: RenderErrorType[]
}

export const RouteRenderErrorBoundary = ({ catchErrors = [RenderErrorType.OsIsNotAvailable], ...rest }: Props) => {
  const error = useRouteError() as unknown

  console.error(error)

  const mappedError = error instanceof RenderError ? error : new RenderError(RenderErrorType.OsIsNotAvailable)
  const isProcessable = catchErrors.includes(mappedError.cause)

  if (!isProcessable) {
    throw mappedError
  }

  return (
    <RenderErrorBoundaryLayout {...rest}>
      <RenderErrorBoundaryView error={mappedError} />
    </RenderErrorBoundaryLayout>
  )
}
