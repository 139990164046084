import { WppTypography } from '@platform-ui-kit/components-library-react'
import { Trans, useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { Truncate } from 'components/common/truncate/Truncate'
import styles from 'components/notificationsSideModal/notificationCard/NotificationCard.module.scss'
import { FeedContext, EventName } from 'types/feeds/feeds'

export const HierarchyRequestedCardContent = ({ userName }: { userName: FeedContext['user_name'] }) => (
  <WppTypography type="s-body" data-testid="notification-card-content-hierarchy-requested">
    <Trans
      i18nKey="os.notification.access.hierarchy.requested_access"
      values={{
        userName,
      }}
      components={[
        <b key="0" className={styles.bold}>
          userName
        </b>,
      ]}
    />
  </WppTypography>
)

export const TenantRequestedCardContent = ({
  userName,
  tenantName,
}: {
  userName: FeedContext['user_name']
  tenantName: FeedContext['tenant_name']
}) => (
  <Truncate type="s-body" lines={2} data-testid="notification-card-content-tenant-requested">
    <Trans
      i18nKey="os.notification.access.tenant.requested_access"
      values={{
        userName,
        tenantName,
      }}
      components={[
        <b key="0" className={styles.bold}>
          userName
        </b>,
        <b key="1" className={styles.bold}>
          tenantName
        </b>,
      ]}
    />
  </Truncate>
)

export const HierarchyAccessInfoCardContent = ({
  eventName,
  requestReason,
  rejectReason,
}: {
  eventName: EventName.HIERARCHY_ACCESS_REJECTED | EventName.HIERARCHY_ACCESS_APPROVED
  requestReason: FeedContext['request_reason']
  rejectReason: FeedContext['reject_reason']
}) => {
  const { t } = useTranslation()
  const isRequestApproved = eventName === EventName.HIERARCHY_ACCESS_APPROVED

  return (
    <Flex direction="column">
      <WppTypography type="s-body" data-testid="notification-card-content-hierarchy-access">
        <Trans
          i18nKey={`os.notification.access.${isRequestApproved ? 'approved_access' : 'rejected_access'}`}
          values={{
            type: t('os.notification.access.hierarchy.navigation'),
          }}
          components={[
            <b key="0" className={styles.bold}>
              userName
            </b>,
          ]}
        />
      </WppTypography>
      {(requestReason || rejectReason) && (
        <Truncate lines={1} type="xs-body" className={styles.greyTypography} data-testid="notification-card-reason">
          {isRequestApproved ? requestReason : rejectReason}
        </Truncate>
      )}
    </Flex>
  )
}

export const CustomNotificationCardContent = ({ text }: { text: FeedContext['body'] }) => (
  <Truncate lines={2} type="s-body" className={styles.message} data-testid="notification-card-content-custom">
    {text}
  </Truncate>
)
