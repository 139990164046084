import { Route } from 'react-router-dom'

import { TermsOfService } from 'pages/termsOfService/TermsOfService'
import { routesManager } from 'utils/routesManager'

export const publicRoutes = (
  <>
    <Route path={routesManager.public.terms.routePath} element={<TermsOfService />} />
  </>
)
