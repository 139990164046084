import { useEffect } from 'react'
import { useAuth } from 'react-oidc-context'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { AuthProvider } from 'providers/auth/AuthProvider'
import { getTargetUrl } from 'providers/auth/utils'
import { ProtectedApiProvider } from 'providers/protectedApi/ProtectedApiProvider'
import { SignInUserState } from 'types/auth/state'
import { routesManager } from 'utils/routesManager'

export const CheckAuth = () => {
  const { isAuthenticated, events, error, removeUser } = useAuth()
  const location = useLocation()

  // Watch for sign out to update the status
  useEffect(() => {
    const signedOutCallback = () => {
      removeUser()
    }

    events.addUserSignedOut(signedOutCallback)

    return () => {
      events.removeUserSignedOut(signedOutCallback)
    }
  }, [events, removeUser])

  // Handle 'react-oidc-context' errors
  useEffect(() => {
    if (error) {
      console.error('Authentication error:', error)
      removeUser()
    }
  }, [error, removeUser])

  return (
    <>
      {isAuthenticated && !error ? (
        <AuthProvider>
          <ProtectedApiProvider>
            <Outlet />
          </ProtectedApiProvider>
        </AuthProvider>
      ) : (
        <Navigate
          replace
          to={routesManager.login.url()}
          state={
            {
              targetUrl: getTargetUrl(location),
            } as SignInUserState
          }
        />
      )}
    </>
  )
}
