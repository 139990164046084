import { WppSpinner, WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/appsNavigation/noCodeApp/noCodeAppLoading/NoCodeAppLoading.module.scss'

export const NoCodeAppLoading = () => {
  const { t } = useTranslation()

  return (
    <Flex className={styles.root} direction="column" justify="center" align="center" gap={24}>
      <WppSpinner size="m" />
      <Flex direction="column" gap={8}>
        <WppTypography type="xl-heading">{t('os.lean_app_page.loading_state.title')}</WppTypography>
        <WppTypography type="s-body">{t('os.lean_app_page.loading_state.description')}</WppTypography>
      </Flex>
    </Flex>
  )
}
