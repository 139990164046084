import { excludeFalsy, isBoolean, isNumber, isObject } from 'utils/common'

/**
 *
 * const input = {
 *  "page": 1,
 *  "itemsPerPage": 50,
 *  "sort": "name",
 *  "filter": {
 *    "search": "",
 *    "role_id": []
 *    "user_id": undefined
 *  }
 * }
 *
 * skipEmptyParams(input) -> { "page": 1, "itemsPerPage": 50, "sort": "name" }
 */

export const skipEmptyParams = (params: Record<string, any>): Record<string, any> =>
  Object.fromEntries(
    Object.entries(params)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          return value.length ? [key, value] : null
        }

        if (isObject(value)) {
          const res = skipEmptyParams(value)

          return Reflect.ownKeys(res).length ? [key, res] : null
        }

        return value || isNumber(value) || isBoolean(value) ? [key, value] : null
      })
      .filter(excludeFalsy),
  )
