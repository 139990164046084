import {
  WppActionButton,
  WppIconCross,
  WppBackToTopButton,
  WppTooltip,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useIntersection } from 'react-use'

import { Flex } from 'components/common/flex/Flex'
import { AppGroupAppsNavigationMenuColumn } from 'components/navigationMenu/navigationMenuContent/appGroupAppsNavigationMenuColumn/AppGroupAppsNavigationMenuColumn'
import { AppsNavigationMenuColumn } from 'components/navigationMenu/navigationMenuContent/appsNavigationMenuColumn/AppsNavigationMenuColumn'
import { ComposedNavigationMenuColumn } from 'components/navigationMenu/navigationMenuContent/composedNavigationMenuColumn/ComposedNavigationMenuColumn'
import { HierarchyNavigationMenuColumn } from 'components/navigationMenu/navigationMenuContent/hierarchyNavigationMenuColumn/HierarchyNavigationMenuColumn'
import styles from 'components/navigationMenu/navigationMenuContent/NavigationMenuContent.module.scss'
import {
  NavigationMenuControllerProvider,
  useNavigationMenuController,
} from 'components/navigationMenu/navigationMenuContent/NavigationMenuControllerContext'
import { ProjectsNavigationMenuColumn } from 'components/navigationMenu/navigationMenuContent/projectsNavigationMenuColumn/ProjectsNavigationMenuColumn'
import { useTenantLogoAndMetaData } from 'hooks/useTenantLogoAndMetaData'
import { useHeaderNavigation } from 'layout/header/headerNavigationProvider/HeaderNavigationProvider'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { useOsState } from 'providers/osState/OsStateProvider'
import { sortBy } from 'utils/common'
import {
  getIsComposedColumnVisible,
  getIsHierarchyColumnVisible,
  getIsProjectsColumnVisible,
} from 'utils/mapping/navigationTree'

const NavigationMenuContentImplementation = () => {
  const { t } = useTranslation()
  const { logoOriginal } = useTenantLogoAndMetaData()
  const { currentTenant, navigationHierarchy } = useCurrentTenantData()
  const { closeNavigation } = useOsState()
  const { nodesMapping } = useHeaderNavigation()
  const { customMenuAppInstances, selectedNavigationNodeId, selectedCustomMenuGroupId } = useNavigationMenuController()

  const pointerNode = nodesMapping[selectedNavigationNodeId]

  const scrollParentRef = useRef<HTMLDivElement>(null)
  const trackRef = useRef<HTMLDivElement>(null)

  const intersection = useIntersection(trackRef, {
    root: scrollParentRef.current,
    rootMargin: '45px',
  })

  const groupAppInstances = useMemo(() => {
    const filtered = customMenuAppInstances.filter(
      appInstance => !!selectedCustomMenuGroupId && appInstance.group?.id === selectedCustomMenuGroupId,
    )

    return sortBy(filtered, ({ position }) => position)
  }, [customMenuAppInstances, selectedCustomMenuGroupId])

  const isHeaderPinned = !(intersection && intersection.isIntersecting)
  const isComposedColumnVisible = getIsComposedColumnVisible(currentTenant)
  const isProjectsColumnVisible = getIsProjectsColumnVisible(pointerNode)
  const isHierarchyColumnVisible = getIsHierarchyColumnVisible({
    pointerNode,
    navigationHierarchy,
  })
  const isCustomMenuAppGroupVisible =
    isComposedColumnVisible && !isHierarchyColumnVisible && !isProjectsColumnVisible && groupAppInstances.length > 0

  return (
    <div data-testid="navigation-menu-content" className={styles.root}>
      <Flex
        align="center"
        gap={16}
        className={clsx(styles.header, {
          [styles.pinned]: isHeaderPinned,
        })}
      >
        <WppActionButton variant="secondary" onClick={closeNavigation} data-testid="navigation-menu-cross-button">
          <WppIconCross slot="icon-start" />
        </WppActionButton>

        <img className={styles.logo} src={logoOriginal} alt="Tenant logo" />
      </Flex>

      <div ref={scrollParentRef} className={styles.container} data-testid="navigation-menu-scrollable">
        {/* Node to track intersection on scroll to determine header pin state */}
        <div aria-hidden ref={trackRef} className={styles.track} />

        <Flex gap={48} className={styles.content} data-testid="navigation-menu-container">
          {isComposedColumnVisible && (
            <>
              <ComposedNavigationMenuColumn />
              {isCustomMenuAppGroupVisible && <AppGroupAppsNavigationMenuColumn appInstances={groupAppInstances} />}
            </>
          )}
          {isHierarchyColumnVisible && (
            <>
              <HierarchyNavigationMenuColumn />
              <AppsNavigationMenuColumn />
            </>
          )}
          {isProjectsColumnVisible && <ProjectsNavigationMenuColumn />}
        </Flex>
      </div>

      {isHeaderPinned && (
        <WppTooltip className={styles.backToTopButton} text={t('os.common.back_to_top')}>
          <WppBackToTopButton
            onClick={() => {
              scrollParentRef.current?.scrollTo({
                top: 0,
                behavior: 'smooth',
              })
            }}
          />
        </WppTooltip>
      )}
    </div>
  )
}

export const NavigationMenuContent = () => (
  <NavigationMenuControllerProvider>
    <NavigationMenuContentImplementation />
  </NavigationMenuControllerProvider>
)
