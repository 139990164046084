export type BreadcrumbsData = BreadcrumbsDataHierarchy | BreadcrumbsDataProjectFluid | BreadcrumbsDataProjectLinear

export interface BreadcrumbsDataHierarchy {
  type: BreadcrumbsType.Hierarchy
  items: BreadcrumbItemHierarchy[]
}

export interface BreadcrumbsDataProjectFluid {
  type: BreadcrumbsType.ProjectFluid
  items: BreadcrumbItemProject[]
}

export interface BreadcrumbsDataProjectLinear {
  type: BreadcrumbsType.ProjectLinear
  items: BreadcrumbItemProject[]
}

export enum BreadcrumbsType {
  Hierarchy = 'Hierarchy',
  ProjectFluid = 'ProjectFluid',
  ProjectLinear = 'ProjectLinear',
}

export type BreadcrumbItemHierarchy = BreadcrumbItem<BreadcrumbItemHierarchyType>
export type BreadcrumbItemProject = BreadcrumbItem<BreadcrumbItemProjectType>

export interface BreadcrumbItem<T> {
  type: T
  label: string
  id?: string
  link?: string
}

export enum BreadcrumbItemHierarchyType {
  Home = 'Home',
  Hierarchy = 'Hierarchy',
  Application = 'Application',
}

export enum BreadcrumbItemProjectType {
  Home = 'Home',
  Projects = 'Projects',
  Project = 'Project',
  Application = 'Application',
}
