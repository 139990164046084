import { Route } from 'react-router-dom'

import { RenderErrorBoundaryHeight, RenderErrorType, RouteRenderErrorBoundary } from 'components/renderError'
import { AuthCallback } from 'pages/AuthCallback'
import { Login } from 'pages/login/Login'
import { CheckAuth } from 'providers/auth/CheckAuth'
import { OsOidcProvider } from 'providers/auth/OsOidcProvider'
import { SilentLogin } from 'providers/auth/SilentLogin'
import { getShouldMonitorSession } from 'providers/auth/utils'
import { OtherTenantsAndUserDataProvider } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataProvider'
import { genericTenantRoutes } from 'routes/genericTenantRoutes'
import { publicRoutes } from 'routes/publicRoutes'
import { getSpecificTenantRoutes } from 'routes/specificTenantRoutes'
import { HostType, KnownHostInfo } from 'types/tenants/tenant'
import { routesManager } from 'utils/routesManager'

export const getAppRoutes = (hostInfo: KnownHostInfo) => (
  <Route path="/*" errorElement={<RouteRenderErrorBoundary />}>
    {publicRoutes}

    <Route loader={getShouldMonitorSession} element={<OsOidcProvider />}>
      <Route path={routesManager.authCallback.routePath} element={<AuthCallback />} />

      <Route element={<SilentLogin />}>
        <Route path={routesManager.login.routePath} element={<Login />} />
        <Route element={<CheckAuth />}>
          <Route element={<OtherTenantsAndUserDataProvider />}>
            <Route
              errorElement={
                /* Protected data issues boundary. */
                <RouteRenderErrorBoundary
                  catchErrors={[
                    RenderErrorType.OsIsNotAvailable,
                    RenderErrorType.DataIsNotAvailable,
                    RenderErrorType.ForbiddenPage,
                    RenderErrorType.ForbiddenOSAccess,
                    RenderErrorType.NotFound,
                    RenderErrorType.CriticalError,
                  ]}
                  height={RenderErrorBoundaryHeight.Fullscreen}
                />
              }
            >
              {hostInfo.type === HostType.GENERIC ? genericTenantRoutes : getSpecificTenantRoutes(hostInfo)}
            </Route>
          </Route>
        </Route>
      </Route>
    </Route>
  </Route>
)
