export enum Theme {
  CHOREOGRAPH = 'choreograph-theme',
  WAVEMAKER = 'wavemaker-theme',
  GREENHOUSE = 'greenhouse-theme',
  GROUPM = 'groupm-theme',
  MINDSHARE = 'mindshare-theme',
  MEDIACOM = 'mediacom-theme',
  MSIX = 'msix-theme',
  ESSENCE = 'essence-theme',
  WPP_OPEN = 'wpp-open-theme',
  WMS = 'wms-theme',
}

export const DEFAULT_THEME = Theme.CHOREOGRAPH

export enum LegacyTenantCode {
  CHOREOGRAPH = 'choreograph',
  WAVEMAKER = 'wavemaker',
  GREENHOUSE = 'greenhouse',
  GROUPM = 'groupm',
  MINDSHARE = 'mindshare',
  MEDIACOM = 'mediacom',
  MSIX = 'msix',
  ESSENCE = 'essence',
  WPP_OPEN = 'wpp-open',
  WMS = 'wms',
}

export interface LegacyTenant {
  name: string
  code: LegacyTenantCode
  defaultTheme: Theme
  mainColor?: string
  id?: string
  organisationsId?: string
  applicationsId?: string
}

export const legacyTenants: LegacyTenant[] = [
  {
    name: 'Choreograph',
    code: LegacyTenantCode.CHOREOGRAPH,
    defaultTheme: Theme.CHOREOGRAPH,
    mainColor: '#ff5c00',
  },
  {
    name: 'Wavemaker',
    code: LegacyTenantCode.WAVEMAKER,
    defaultTheme: Theme.WAVEMAKER,
    mainColor: '#ed6b31',
  },
  {
    name: 'Greenhouse',
    code: LegacyTenantCode.GREENHOUSE,
    defaultTheme: Theme.GREENHOUSE,
    mainColor: '#6300a7',
  },
  {
    name: 'GroupM',
    code: LegacyTenantCode.GROUPM,
    defaultTheme: Theme.GROUPM,
    mainColor: '#007cff',
  },
  {
    name: 'MindShare',
    code: LegacyTenantCode.MINDSHARE,
    defaultTheme: Theme.MINDSHARE,
    mainColor: '#791c99',
  },
  {
    name: 'MediaCom',
    code: LegacyTenantCode.MEDIACOM,
    defaultTheme: Theme.MEDIACOM,
    mainColor: '#e1005d',
  },
  {
    name: 'Essence',
    code: LegacyTenantCode.ESSENCE,
    defaultTheme: Theme.ESSENCE,
    mainColor: '#ea533c',
  },
  {
    name: 'mSix',
    code: LegacyTenantCode.MSIX,
    defaultTheme: Theme.MSIX,
    mainColor: '#009FE3',
  },
  {
    name: 'WPP Open',
    code: LegacyTenantCode.WPP_OPEN,
    defaultTheme: Theme.WPP_OPEN,
    mainColor: '#7800db',
  },
  {
    name: 'WPP Media Services',
    code: LegacyTenantCode.WMS,
    defaultTheme: Theme.WMS,
    mainColor: '#007cff',
  },
]

export const DEFAULT_LEGACY_TENANT = legacyTenants.find(({ defaultTheme }) => defaultTheme === DEFAULT_THEME)!
