import { facadeApi } from 'api'

interface Props {
  tenantId: string
  ids: string[]
}

export const updateFeedsApi = ({ tenantId, ids }: Props) => {
  return facadeApi.patch(
    '/feeds',
    {
      ids,
      updates: {
        isRead: true,
      },
    },
    {
      headers: {
        'X-Tenant-Id': tenantId,
      },
    },
  )
}
