import { PropsWithChildren, useState } from 'react'

import { IntercomWidget } from 'components/intercom/IntercomWidget'
import { ZendeskWidget } from 'components/zendesk/ZendeskWidget'
import { environment } from 'environment'
import { useOsRoot } from 'providers/osRoot/OsRootContext'
import { OsSupportServiceContext } from 'providers/osSupportService/OsSupportServiceContext'
import { HostType, SupportService } from 'types/tenants/tenant'

export const OsSupportServiceProvider = ({ children }: PropsWithChildren<{}>) => {
  const { hostInfo } = useOsRoot()
  const [isZendeskActive, setIsZendeskActive] = useState(false)
  const [isIntercomActive, setIsIntercomActive] = useState(false)

  const { type, currentTenant } = hostInfo

  const isIntercomEnabled =
    !!environment.INTERCOM_APP_ID &&
    (type === HostType.GENERIC || currentTenant?.supportService === SupportService.Intercom)

  const isZendeskEnabled = !!environment.ZENDESK_KEY && currentTenant?.supportService === SupportService.Zendesk

  return (
    <OsSupportServiceContext.Provider
      value={{
        zendesk: {
          enabled: isZendeskEnabled,
          active: isZendeskActive,
          onActiveStateChange: setIsZendeskActive,
        },
        intercom: {
          enabled: isIntercomEnabled,
          active: isIntercomActive,
          onActiveStateChange: setIsIntercomActive,
        },
      }}
    >
      {isZendeskEnabled && <ZendeskWidget />}
      {isIntercomEnabled && <IntercomWidget />}
      {children}
    </OsSupportServiceContext.Provider>
  )
}
