import { Portal } from 'components/common/portal/Portal'
import { Script } from 'components/common/script/Script'
import { environment } from 'environment'
import { useOsSupportService } from 'providers/osSupportService/OsSupportServiceContext'
import { SupportService } from 'types/tenants/tenant'

declare global {
  interface Window {
    Intercom?: (command: string, props?: any) => void
    intercomSettings?: { [k: string]: string }
    intercomQueue?: [string, {}][]
  }
}

export const IntercomWidget = () => {
  const { onActiveStateChange } = useOsSupportService(SupportService.Intercom)

  return (
    <Portal target={document.head}>
      <Script
        async
        src={`https://widget.intercom.io/widget/${environment.INTERCOM_APP_ID}`}
        onLoad={() => {
          window.Intercom?.('boot', {
            api_base: environment.INTERCOM_API_BASE,
            app_id: environment.INTERCOM_APP_ID,
          })

          onActiveStateChange(true)
        }}
        onError={() => {
          onActiveStateChange(false)
        }}
      />
    </Portal>
  )
}
