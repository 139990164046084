import { WppSelect, WppListItem, WppDivider } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { forwardRef, Ref, ReactNode, ComponentProps, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import styles from 'components/common/select/Select.module.scss'
import { useSelectDropdownConfig } from 'components/common/select/utils'
import { useCommonLabelProps } from 'components/common/utils'

export interface SelectOption {
  label: string
  value: string
}

type WppSelectProps = ComponentProps<typeof WppSelect>

export interface SelectProps<T extends Record<string, any>> extends WppSelectProps {
  options: T[]
  isOptionDisabled?: (option: T) => boolean
  getOptionValue?: (option: T) => string | number
  getOptionLabel?: (option: T) => string
  renderOptionContent?: (option: T) => ReactNode
  customOption?: JSX.Element
  'data-testid'?: string
}

export const Select = forwardRef(function Select<T extends Record<string, any>>(
  {
    options,
    dropdownPosition = 'fixed',
    getOptionValue = option => option.value,
    getOptionLabel = option => option.label,
    renderOptionContent,
    isOptionDisabled = () => false,
    'data-testid': dataTestId,
    labelConfig,
    labelTooltipConfig,
    locales,
    dropdownConfig,
    className,
    customOption,
    withSearch,
    ...rest
  }: SelectProps<T>,
  ref: Ref<HTMLWppSelectElement>,
) {
  const { t } = useTranslation()
  const labelProps = useCommonLabelProps({ labelConfig, labelTooltipConfig })
  const dropdownConfigInner = useSelectDropdownConfig(dropdownConfig)

  const defaultLocales = useMemo<NonNullable<WppSelectProps['locales']>>(
    () => ({
      emptyText: t('os.common.select.empty_text'),
      clearAllText: t('os.common.select.clear_all_text'),
      selectAllText: t('os.common.select.select_all_text'),
      searchInputPlaceholder: t('os.common.select.search_input_placeholder'),
      allSelectedText: t('os.common.select.all_selected_text'),
      selectLabel: t('os.common.select.select_label'),
    }),
    [t],
  )

  return (
    <WppSelect
      ref={ref}
      {...rest}
      {...labelProps}
      withSearch={withSearch}
      // TODO WPPLONOP-19180
      className={clsx(styles.select, { [styles.withSearch]: withSearch }, className)}
      dropdownPosition={dropdownPosition}
      locales={locales ? { ...defaultLocales, ...locales } : defaultLocales}
      dropdownConfig={dropdownConfigInner}
      data-testid={dataTestId}
    >
      <>
        {!!customOption && (
          <>
            {customOption}
            <WppDivider className={styles.divider} />
          </>
        )}
        {options.map(option => {
          const optionValue = getOptionValue(option)

          return (
            <WppListItem key={optionValue} value={optionValue} disabled={isOptionDisabled(option)}>
              {renderOptionContent ? renderOptionContent?.(option) : <span slot="label">{getOptionLabel(option)}</span>}
            </WppListItem>
          )
        })}
      </>
    </WppSelect>
  )
}) as <T extends Record<string, any>>(props: SelectProps<T>) => JSX.Element
