import segmentPlugin from '@analytics/segment'
import { AnalyticsAction, AnalyticsPageAction, AnalyticsTrackAction, AnalyticsActionType } from '@wpp-open/core'
import Analytics from 'analytics'

import { environment } from 'environment'
import { excludeFalsy, isString, noop } from 'utils/common'

export const analytics = Analytics({
  app: 'os-app',
  plugins: [
    !!environment.SEGMENT_ANALYTICS_KEY &&
      segmentPlugin({
        writeKey: environment.SEGMENT_ANALYTICS_KEY,
      }),
  ].filter(excludeFalsy),
})

export const reset = () => {
  analytics.reset()
}

const getAnalyticsTenantAzId = () => analytics?.getState()?.user?.traits?.tenantAzId

const trackPage = ({ payload }: AnalyticsPageAction) => {
  const analyticsTenantAzId = getAnalyticsTenantAzId()
  if (analyticsTenantAzId) {
    analytics.page(
      {
        title: payload,
      },
      {
        agency: analyticsTenantAzId,
      },
    )
  }
}

const trackAction = ({ payload }: AnalyticsTrackAction) => {
  const analyticsTenantAzId = getAnalyticsTenantAzId()
  if (analyticsTenantAzId) {
    if (isString(payload)) {
      analytics.track(payload, {
        agency: analyticsTenantAzId,
      })
    } else {
      const { action, params } = payload

      analytics.track(action, {
        agency: analyticsTenantAzId,
        ...Object.fromEntries(params.map(({ key, value }) => [key, value])),
      })
    }
  }
}

export const trackAnalytics = process.env.DEV
  ? noop
  : (action: AnalyticsAction) => {
      switch (action.type) {
        case AnalyticsActionType.page:
          trackPage(action)
          break

        case AnalyticsActionType.action:
          trackAction(action)
          break

        default:
          break
      }
    }
