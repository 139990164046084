import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as zod from 'zod'

export const MAX_CHARACTERS = 200

export const useValidationScheme = () => {
  const { t } = useTranslation()
  return useMemo(
    () =>
      zod.object({
        tenantId: zod.string().min(1, t('os.request_access_modal.tenant.os_field.validation')),
        requestReason: zod
          .string()
          .min(
            1,
            t('os.common.errors.min_max_length', {
              entity: t('os.entities.request_reason'),
              min: 1,
              max: MAX_CHARACTERS,
            }),
          )
          .max(
            MAX_CHARACTERS,
            t('os.common.errors.min_max_length', {
              entity: t('os.entities.request_reason'),
              min: 1,
              max: MAX_CHARACTERS,
            }),
          ),
      }),
    [t],
  )
}
