import { WppCard, WppSkeleton } from '@platform-ui-kit/components-library-react'
import { Tenant } from '@wpp-open/core'

import { useUpdateFeedsApi } from 'api/feeds/mutations/useUpdateFeedsApi'
import { Avatar } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import { showNotificationItemSideModal } from 'components/notificationItemSideModal/NotificationItemSideModal'
import styles from 'components/notificationsSideModal/notificationCard/NotificationCard.module.scss'
import {
  CustomNotificationCardContent,
  HierarchyAccessInfoCardContent,
  HierarchyRequestedCardContent,
  TenantRequestedCardContent,
} from 'components/notificationsSideModal/notificationCard/NotificationCardContent'
import { NotificationHeader } from 'components/notificationsSideModal/notificationHeader/NotificationHeader'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { queryClient } from 'providers/osQueryClient/utils'
import { useOsRoot } from 'providers/osRoot/OsRootContext'
import { Feed, EventName } from 'types/feeds/feeds'
import { TenantShort } from 'types/tenants/tenant'

interface Props {
  notification: Feed
  tenant: TenantShort | Tenant
}

export const NotificationCard = ({ notification, tenant }: Props) => {
  const { defaultTenant } = useOsRoot()
  const { logoThumbnail, id: tenantId, name } = tenant
  const { mutateAsync: handleUpdateFeeds } = useUpdateFeedsApi()
  const { id, isRead, context, createdAt, eventName } = notification
  const { tenant_name, user_name, request_reason, reject_reason, body } = context
  const logoUrl = logoThumbnail?.url || defaultTenant.logoThumbnail?.url

  const handleCardClick = async () => {
    showNotificationItemSideModal({ notification, logoUrl })
    if (!isRead) {
      await handleUpdateFeeds({ tenantId, ids: [id] })
      await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.USER_FEEDS] })
    }
  }

  return (
    <div className={styles.cardWrapper} data-testid="notification-card">
      <WppCard size="s" onClick={handleCardClick} className={styles.card}>
        <Flex gap={12}>
          <Avatar
            size="m"
            name={name}
            src={logoUrl || undefined}
            className={styles.avatar}
            data-testid="notification-card-logo"
          />
          <Flex direction="column" gap={4}>
            <NotificationHeader createdAt={createdAt} />

            {eventName === EventName.HIERARCHY_ACCESS_REQUESTED_ADMIN && (
              <HierarchyRequestedCardContent userName={user_name} />
            )}

            {(eventName === EventName.HIERARCHY_ACCESS_REJECTED ||
              eventName === EventName.HIERARCHY_ACCESS_APPROVED) && (
              <HierarchyAccessInfoCardContent
                eventName={eventName}
                requestReason={request_reason}
                rejectReason={reject_reason}
              />
            )}

            {eventName === EventName.TENANT_ACCESS_REQUESTED_ADMIN && (
              <TenantRequestedCardContent userName={user_name} tenantName={tenant_name} />
            )}

            {eventName === EventName.ADMIN_NOTIFICATION_REQUEST && <CustomNotificationCardContent text={body} />}
          </Flex>
        </Flex>
      </WppCard>
      {!isRead && <div className={styles.unread} data-testid="notification-unread-indicator" />}
    </div>
  )
}

export const NotificationCardSkeleton = () => {
  return (
    <WppCard>
      <Flex gap={20}>
        <WppSkeleton width={40} height={40} />
        <Flex direction="column" gap={5}>
          <WppSkeleton width={200} height={15} />
          <WppSkeleton width={400} height={20} />
          <WppSkeleton width={300} height={20} />
        </Flex>
      </Flex>
    </WppCard>
  )
}
