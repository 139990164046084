import { useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'

import { useUpdateUserMiroAuthToken } from 'api/miro/mutations/useUpdateUserMiroAuthToken'
import { useStableCallback } from 'hooks/useStableCallback'
import { LoadingPage } from 'layout/loadingPage/LoadingPage'
import {
  MiroAuthRedirectReason,
  MiroAuthCallbackParams,
  useMiroAuthCallbackParams,
  isValidMiroAuthCallbackParams,
} from 'utils/miro'

interface MiroAuthHistoryState {
  clientId: string
  teamId: string
  authCode: string
  context?: any
}

const getMiroAuthHistoryState = ({
  clientId,
  teamId,
  authCode,
  state,
}: MiroAuthCallbackParams): MiroAuthHistoryState => ({
  clientId,
  teamId,
  authCode,
  context: state.context,
})

export const MiroAuthCallback = () => {
  const params = useMiroAuthCallbackParams()

  if (isValidMiroAuthCallbackParams(params)) {
    const { state } = params
    const { from, reason } = state

    const miroAuthHistoryState = getMiroAuthHistoryState(params)

    if (reason === MiroAuthRedirectReason.Settings) {
      return <Navigate to={from} state={miroAuthHistoryState} />
    }

    if (reason === MiroAuthRedirectReason.AuthToken) {
      return <MiroAuthTokenCallback to={from} state={miroAuthHistoryState} />
    }
  }

  return <Navigate to="/" />
}

function MiroAuthTokenCallback({ to, state }: { to: string; state: MiroAuthHistoryState }) {
  const navigate = useNavigate()
  const { mutateAsync: handleUpdateUserMiroAuthToken } = useUpdateUserMiroAuthToken()

  const handleSetTokenAndRedirect = useStableCallback(async () => {
    try {
      await handleUpdateUserMiroAuthToken({ authCode: state.authCode })

      navigate(to, { state })
    } catch {
      navigate('/')
    }
  })

  useEffect(() => {
    handleSetTokenAndRedirect()
  }, [handleSetTokenAndRedirect])

  return <LoadingPage />
}
