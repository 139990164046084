import { WppCard, WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { AvatarWithNameAndEmailCell } from 'components/cellRenderers/avatarWithNameAndEmail/AvatarWithNameAndEmailCell'
import { Flex } from 'components/common/flex/Flex'
import { useUsernameFormat } from 'hooks/useUsernameFormat'
import styles from 'pages/admin/requests/hierarchyAccessRequests/usersInfoCard/UsersInfoCard.module.scss'
import { HierarchyAccessRequest } from 'types/requests/requests'

interface Props {
  request: HierarchyAccessRequest
  getAttachmentUrlByKey: (key?: string) => string
}

export const UsersInfoCard = ({ request, getAttachmentUrlByKey }: Props) => {
  const { formatUsername } = useUsernameFormat()
  const { t } = useTranslation()
  const { firstname, lastname, email, avatarThumbnail } = request?.userData || {}

  const userName = formatUsername(firstname, lastname)

  return (
    <WppCard className={styles.card}>
      <Flex direction="column" gap={8}>
        <AvatarWithNameAndEmailCell
          email={email || ''}
          userName={userName}
          avatarThumbnail={getAttachmentUrlByKey(avatarThumbnail?.key)}
        />
        <Flex direction="column">
          <WppTypography type="s-strong" className={styles.request}>
            {t('os.requests.hierarchy_access_requests.actions_modal.request')}
          </WppTypography>
          <WppTypography type="s-body">{request.requestReason}</WppTypography>
        </Flex>
      </Flex>
    </WppCard>
  )
}
