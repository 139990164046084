import { useLocation } from 'react-router-dom'

import { getAppInstanceDataFromUrl } from 'providers/osState/utils/appDataFromUrl/getAppInstanceDataFromUrl'
import { getSystemAppDataFromUrl } from 'providers/osState/utils/appDataFromUrl/getSystemAppDataFromUrl'
import { useLocalAppDataFromUrl } from 'providers/osState/utils/appDataFromUrl/useLocalAppDataFromUrl'
import { useLocalContextFromUrl } from 'providers/osState/utils/appDataFromUrl/useLocalContextFromUrl'
import { useLocalLegacyAppDataFromUrl } from 'providers/osState/utils/appDataFromUrl/useLocalLegacyAppDataFromUrl'
import { AppDataFromUrl, AppDataFromUrlType, InvalidDataFromUrl } from 'types/osState/appDataFromUrl'

export const useAppDataFromUrl = (): AppDataFromUrl => {
  const { pathname, search, hash } = useLocation()
  const localContext = useLocalContextFromUrl()
  const localAppDataFromUrl = useLocalAppDataFromUrl(localContext)
  const localLegacyAppDataFromUrl = useLocalLegacyAppDataFromUrl(localContext)

  // TODO: Update knowledge base with new app routes
  return (
    localAppDataFromUrl ||
    localLegacyAppDataFromUrl ||
    getSystemAppDataFromUrl({ pathname }) ||
    getAppInstanceDataFromUrl({ pathname, search, hash }) ||
    getInvalidAppDataFromUrl()
  )
}

const getInvalidAppDataFromUrl = (): InvalidDataFromUrl => ({
  type: AppDataFromUrlType.Invalid,
})
