import { facadeApi } from 'api'
import { SignUpResponse } from 'types/signUp/signUpRequest'

export interface SignUpRequestFormDto {
  firstName: string
  lastName: string
  email: string
}

export const createSignUpRequestApi = ({ userData, tenantId }: { userData: SignUpRequestFormDto; tenantId: string }) =>
  facadeApi.post<SignUpResponse>('/sign-up-requests', { ...userData, tenantId })
