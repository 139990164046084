import { WppTypography } from '@platform-ui-kit/components-library-react'
import { Trans, useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { SvgForbidden } from 'components/svg/renderError/SvgForbidden'
import styles from 'pages/appsNavigation/noCodeApp/miroBoardAppRenderer/MiroBoardAppRenderer.module.scss'
import { mailto } from 'utils/links'

export const MiroAdminIssuesState = ({ email = '' }: { email?: string }) => {
  const { t } = useTranslation()

  return (
    <Flex justify="center" align="center" direction="column" className={styles.fullHeight} gap={20}>
      <SvgForbidden />
      <WppTypography className={styles.title} type="xl-heading">
        {t('os.navigation_menu.miro_admin_not_authorized.title')}
      </WppTypography>
      <WppTypography type="s-body" className={styles.issueDescription}>
        <Trans
          i18nKey="os.navigation_menu.miro_admin_not_authorized.description"
          values={{
            email,
          }}
          components={[
            <a key="0" href={mailto(email)} className={styles.email}>
              email
            </a>,
          ]}
        />
      </WppTypography>
    </Flex>
  )
}
