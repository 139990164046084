import { WppActionButton, WppIconMore, WppListItem, WppMenuContext } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { PropsWithChildren, useMemo, useState } from 'react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'layout/header/breadcrumbs/common/container/BreadcrumbsContainer.module.scss'
import { BreadcrumbsContainerItemTag, useFitBreadcrumbs } from 'layout/header/breadcrumbs/common/container/utils'
import { BreadcrumbItem } from 'layout/header/breadcrumbs/common/item/BreadcrumbItem'
import { BreadcrumbsSeparator } from 'layout/header/breadcrumbs/common/separator/BreadcrumbsSeparator'
import { BreadcrumbItem as BreadcrumbItemType } from 'types/osState/breadcrumbs'

interface Props<T> {
  items: BreadcrumbItemType<T>[]
  onItemClick: (item: BreadcrumbItemType<T>) => void
}

export function BreadcrumbsContainer<T>({ children, items, onItemClick }: PropsWithChildren<Props<T>>) {
  const [hiddenItemCount, setHiddenItemCount] = useState(0)
  const [rootRef, setRootRef] = useState<MayBeNull<HTMLDivElement>>(null)
  const [overflowContainerRef, setOverflowContainerRef] = useState<MayBeNull<HTMLDivElement>>(null)

  const shownItems = useMemo(() => items.slice(hiddenItemCount), [hiddenItemCount, items])
  const hiddenItems = useMemo(() => items.slice(0, hiddenItemCount).reverse(), [hiddenItemCount, items])

  useFitBreadcrumbs({
    rootRef,
    overflowContainerRef,
    items,
    hiddenItemCount,
    setHiddenItemCount,
  })

  return (
    <Flex className={styles.root} ref={node => setRootRef(node)} align="center">
      <Flex ref={node => setOverflowContainerRef(node)} align="center">
        {!!hiddenItems.length && (
          <Flex className={BreadcrumbsContainerItemTag.MoreMenuItem} align="center">
            <WppMenuContext
              dropdownConfig={{
                placement: 'bottom-start',
                offset: [-8, 0],
              }}
            >
              <WppActionButton slot="trigger-element" variant="secondary">
                <WppIconMore direction="horizontal" slot="icon-start" />
              </WppActionButton>

              <Flex gap={4} direction="column">
                {hiddenItems.map((item, index) => (
                  <WppListItem key={item.id || index} title={item.label} onWppChangeListItem={() => onItemClick(item)}>
                    <span slot="label">{item.label}</span>
                  </WppListItem>
                ))}
              </Flex>
            </WppMenuContext>
            <BreadcrumbsSeparator />
          </Flex>
        )}

        {shownItems.map((item, index) => (
          <Flex key={item.id || index} className={BreadcrumbsContainerItemTag.BreadcrumbItem} align="center">
            <BreadcrumbItem label={item.label} link={item.link} onClick={() => onItemClick(item)} />
            <BreadcrumbsSeparator />
          </Flex>
        ))}

        <div>{children}</div>

        <div className={clsx(styles.hiddenItems, BreadcrumbsContainerItemTag.HiddenItems)}>
          {hiddenItems.map((item, index) => (
            <Flex key={item.id || index} className={styles.hiddenItem} align="center">
              <BreadcrumbItem label={item.label} link={item.link} onClick={() => onItemClick(item)} />
              <BreadcrumbsSeparator />
            </Flex>
          ))}
        </div>
      </Flex>
    </Flex>
  )
}
