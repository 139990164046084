import { HierarchyLevelType, MayBeNull } from '@wpp-open/core'

import { LOCAL_APP_CODE, LOCAL_APP_NAME, LOCAL_LEGACY_APP_CODE, LOCAL_LEGACY_CONTAINER_ID } from 'constants/apps'
import { Permission } from 'constants/permission'
import { LegacyAppWindowLibraryName } from 'legacy/types/apps'
import { BaseAppConfig } from 'types/apps/apps'

export enum MicroAppType {
  System = 'System',
  Native = 'Native',
  Legacy = 'Legacy',
  Local = 'Local',
  LocalLegacy = 'LocalLegacy',
}

export enum MicroAppLibraryType {
  Window = 'WINDOW',
  SystemJS = 'SYSTEM_JS',
  ESM = 'ESM',
}

export enum SystemAppCode {
  DevHub = 'SYSTEM_DEVHUB',
  Marketplace = 'SYSTEM_MARKETPLACE',
  Orchestration = 'SYSTEM_ORCHESTRATION',
  News = 'SYSTEM_NEWS',
  ThemeBuilder = 'SYSTEM_THEME_BUILDER',
}

export type MicroAppConfig =
  | SystemMicroAppConfig
  | NativeMicroAppConfig
  | LocalMicroAppConfig
  | LegacyMicroAppConfig
  | LocalLegacyMicroAppConfig

interface BaseMicroAppConfig extends BaseAppConfig {
  osRoute: string
  libraryType: MicroAppLibraryType
  windowLibraryName: MayBeNull<string>
  bundleUrl: string
  permission: MayBeNull<string>
  requiredHierarchy: HierarchyLevelType[]
}

export interface SystemMicroAppConfig extends BaseMicroAppConfig {
  type: MicroAppType.System
  stableId: SystemAppCode
  libraryType: Exclude<MicroAppLibraryType, MicroAppLibraryType.Window>
  windowLibraryName: null
  permission: MayBeNull<Permission>
  requiredHierarchy: [HierarchyLevelType.Tenant]
}

export interface NativeMicroAppConfig extends BaseMicroAppConfig {
  type: MicroAppType.Native
}

export interface LocalMicroAppConfig extends BaseMicroAppConfig {
  type: MicroAppType.Local
  stableId: typeof LOCAL_APP_CODE
  name: typeof LOCAL_APP_NAME
  permission: null
  requiredHierarchy: never[]
}

interface BaseLegacyMicroAppConfig extends BaseMicroAppConfig {
  libraryType: MicroAppLibraryType.Window
  windowLibraryName: string
  containerId: string
}

export interface LegacyMicroAppConfig extends BaseLegacyMicroAppConfig {
  type: MicroAppType.Legacy
  windowLibraryName: LegacyAppWindowLibraryName
}

export interface LocalLegacyMicroAppConfig extends BaseLegacyMicroAppConfig {
  type: MicroAppType.LocalLegacy
  stableId: typeof LOCAL_LEGACY_APP_CODE
  name: typeof LOCAL_APP_NAME
  permission: null
  requiredHierarchy: never[]
  containerId: typeof LOCAL_LEGACY_CONTAINER_ID
}
