import { WppSelect } from '@platform-ui-kit/components-library-react'
import { ComponentProps } from 'react'

import { useIsInsideModal } from 'components/surface/IsInsideModalContext'

type SelectDropdownConfig = NonNullable<ComponentProps<typeof WppSelect>['dropdownConfig']>
type Modifier = NonNullable<NonNullable<SelectDropdownConfig['popperOptions']>['modifiers']>[number]

const modifiers: Modifier[] = [
  // TODO: copy paste from CL to make it work. Internally modifiers are merged incorrectly.
  // Remove after CL fix
  {
    enabled: true,
    fn: ({ instance, state }) => {
      const triggerReferenceWidth = `${state.rects.reference.width}px`

      if (state.styles.popper.width !== triggerReferenceWidth) {
        state.styles.popper.width = triggerReferenceWidth
        // force update the popper instance, so it can reposition the dropdown properly
        instance.update()
      }
    },
    phase: 'beforeWrite',
  },
  {
    name: 'flip',
    options: {
      // Disallow actions slot to overlap dropdown
      padding: { bottom: 90 },
    },
  },
]

export const useSelectDropdownConfig = (dropdownConfig: SelectDropdownConfig = {}) => {
  const isInsideModal = useIsInsideModal()

  let result: SelectDropdownConfig = dropdownConfig

  if (isInsideModal) {
    const { popperOptions } = result

    result = {
      ...result,
      popperOptions: {
        ...popperOptions,
        modifiers: [...(popperOptions?.modifiers || []), ...modifiers],
      },
    }
  }

  return result
}
