import 'app/App.scss'

import { useMemo } from 'react'
import { createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom'

import { usePopulateConfigurableCssVariables } from 'app/utils'
import { RootRenderErrorBoundary } from 'components/renderError'
import { OsQueryClientProvider } from 'providers/osQueryClient'
import { useOsRoot } from 'providers/osRoot/OsRootContext'
import { OsRootProvider } from 'providers/osRoot/OsRootProvider'
import { OsSupportServiceProvider } from 'providers/osSupportService/OsSupportServiceProvider'
import { PublicDataProvider } from 'providers/publicData/PublicDataProvider'
import { ToastProvider } from 'providers/toast/ToastProvider'
import { getAppRoutes } from 'routes'

const AppRouter = () => {
  const { hostInfo } = useOsRoot()

  const router = useMemo(() => {
    const routeElements = getAppRoutes(hostInfo)

    return createBrowserRouter(createRoutesFromElements(routeElements))
  }, [hostInfo])

  return (
    <RouterProvider
      // Key is important for dev mode to re-create the whole router in generic flow simulation.
      {...(process.env.DEV && { key: hostInfo.type })}
      router={router}
      future={{ v7_startTransition: true }}
    />
  )
}

export const App = () => {
  usePopulateConfigurableCssVariables()

  return (
    <RootRenderErrorBoundary>
      <ToastProvider>
        <OsQueryClientProvider>
          <OsRootProvider>
            <PublicDataProvider>
              <OsSupportServiceProvider>
                <AppRouter />
              </OsSupportServiceProvider>
            </PublicDataProvider>
          </OsRootProvider>
        </OsQueryClientProvider>
      </ToastProvider>
    </RootRenderErrorBoundary>
  )
}
