import { MayBeNull } from '@wpp-open/core'
import { AxiosResponse } from 'axios'

import { Unpacked } from 'types/common/utils'

export type CancelableRequestProducer<T> = (signal?: AbortSignal) => Promise<AxiosResponse<T>>

export type QueryFetcher<T extends unknown = unknown, P extends Record<string, any> = any> = (
  params: P,
) => CancelableRequestProducer<T>

export type QueryFetcherParams<F extends QueryFetcher> = Parameters<F>[0]
export type QueryFetcherResponse<F extends QueryFetcher> = Unpacked<ReturnType<ReturnType<F>>>

export class ForcedApiError extends Error {}

export type PaginationParams<T extends Record<string, any>> = T & {
  page?: number
  itemsPerPage?: number
}

export interface PaginatedResponse<T> {
  data: T[]
  navigator: {
    firstPage: string
    lastPage: MayBeNull<string>
    nextPage: MayBeNull<string>
    previousPage: MayBeNull<string>
  }
  paginator: {
    itemsPerPage: number
    page: number
    totalItems: number
    totalPages: number
  }
}

export type SortOrder = 'asc' | 'desc'
