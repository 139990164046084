import { HierarchyLevelType, MayBeNull, NavigationTree, NavigationTreeMapping } from '@wpp-open/core'

import { isHierarchyNode } from 'utils/navigation'

/**
 * Root workspace id goes first,
 * Selected workspace id goes last.
 *
 * E.g. A selected brand with parents:
 *   Client 1 > Market 2 > Brand 3
 * will result in
 *   [1, 2, 3]
 */
export type WorkspaceHierarchy = string[]

export enum WorkspaceType {
  All = 'All',
  Hierarchy = 'Hierarchy',
}

export const isWorkspaceIdValid = ({
  workspaceAzId,
  navigationTree,
  workspaceType = WorkspaceType.Hierarchy,
}: {
  workspaceAzId: MayBeNull<string>
  navigationTree: NavigationTree
  workspaceType?: WorkspaceType
}): boolean => {
  // Empty result is valid. It means a root workspace level
  if (!workspaceAzId) {
    return true
  }

  const { rootId, mapping } = navigationTree
  const isRoot = workspaceAzId === rootId

  // Return empty invalid result if entity for workspaceAzId is not found
  if (!mapping[workspaceAzId]) {
    return false
  }

  // Validate node type
  return workspaceType !== WorkspaceType.Hierarchy || (isHierarchyNode(mapping[workspaceAzId]) && !isRoot)
}

export const resolveActiveHierarchy = ({
  validHierarchyId,
  navigationTree,
}: {
  validHierarchyId: MayBeNull<string>
  navigationTree: NavigationTree
}): WorkspaceHierarchy => {
  if (!validHierarchyId) {
    return []
  }

  const { rootId, mapping } = navigationTree

  const findParentNodeAzId = (childAzId: string) => {
    const parentNode = Object.values(mapping).find(node => node.children.includes(childAzId))
    const parentAzId = parentNode?.azId || null

    return parentAzId !== rootId ? parentAzId : null
  }

  const activeHierarchy: WorkspaceHierarchy = []
  let currentAzId: MayBeNull<string> = validHierarchyId

  do {
    activeHierarchy.unshift(currentAzId)
    currentAzId = findParentNodeAzId(currentAzId)

    if (currentAzId && activeHierarchy.includes(currentAzId)) {
      throw new Error('Circular dependency in Navigation Tree')
    }
  } while (currentAzId)

  return activeHierarchy
}

export const isRequiredHierarchyLevel = ({
  workspaceAzId,
  requiredHierarchy,
  mapping,
}: {
  workspaceAzId: MayBeNull<string>
  requiredHierarchy: HierarchyLevelType[]
  mapping: NavigationTreeMapping
}) => {
  const currentWorkspaceLevel = !workspaceAzId
    ? HierarchyLevelType.Tenant
    : (mapping[workspaceAzId].type as HierarchyLevelType)

  return !requiredHierarchy.length || requiredHierarchy.includes(currentWorkspaceLevel)
}
