import { WppActionButton, WppCard, WppSpinner, WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { Trans, useTranslation } from 'react-i18next'

import { useTenantAccessRequestApi } from 'api/accessRequests/queries/useTenantAccessRequestApi'
import { Avatar } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import styles from 'components/notificationItemSideModal/NotificationItemSideModal.module.scss'
import { showApproveTenantAccessRequestModal } from 'pages/admin/requests/tenantAccessRequests/approveTenantAccessRequestModal/ApproveTenantAccessRequestModal'
import { showRejectTenantAccessRequestModal } from 'pages/admin/requests/tenantAccessRequests/rejectTenantAccessRequestModal/RejectTenantAccessRequestModal'
import { FeedContext } from 'types/feeds/feeds'

interface Props {
  isOpen: boolean
  context: FeedContext
  isAvatarLoading: boolean
  src?: string
}

export const TenantRequestedSideModalContent = ({ isOpen, context, isAvatarLoading, src }: Props) => {
  const { t } = useTranslation()
  const { request_id, user_name, user_email, tenant_name, request_reason } = context
  const { data } = useTenantAccessRequestApi({ params: { id: request_id! }, enabled: isOpen && !!request_id })

  return (
    <>
      <WppTypography type="s-body" data-testid="notification-item-content-tenant-requested">
        <Trans
          i18nKey="os.notification.access.tenant.requested_access"
          values={{
            userName: user_name,
            tenantName: tenant_name,
          }}
          components={[
            <b key="0" className={styles.bold}>
              userName
            </b>,
            <b key="1" className={styles.bold}>
              tenantName
            </b>,
          ]}
        />
      </WppTypography>

      <WppCard size="s" variant="secondary">
        <Flex direction="column" gap={12}>
          <Flex direction="column" gap={8}>
            <Flex align="center" gap={8}>
              {isAvatarLoading ? (
                <Flex className={styles.loader} justify="center" align="center">
                  <WppSpinner size="m" />
                </Flex>
              ) : (
                <Avatar size="xs" name={user_name} src={src} data-testid="notification-item-avatar" />
              )}
              <WppTypography type="s-body" data-testid="notification-item-email">
                {user_email}
              </WppTypography>
            </Flex>

            {request_reason && (
              <WppTypography
                type="xs-body"
                className={clsx(styles.greyTypography, styles.message)}
                data-testid="notification-item-request-reason"
              >
                {request_reason}
              </WppTypography>
            )}
          </Flex>
          <Flex gap={4}>
            <WppActionButton
              loading={!data}
              onClick={() => data && showApproveTenantAccessRequestModal({ request: data })}
              data-testid="notification-item-approve-button"
            >
              {t('os.notification.access.tenant.approve')}
            </WppActionButton>
            <WppActionButton
              loading={!data}
              onClick={() => data && showRejectTenantAccessRequestModal({ request: data })}
              data-testid="notification-item-reject-button"
            >
              {t('os.requests.hierarchy_access_requests.actions.reject')}
            </WppActionButton>
          </Flex>
        </Flex>
      </WppCard>
    </>
  )
}
