import { NavigationItemEventDetail, NavigationState } from '@platform-ui-kit/components-library'
import {
  WppActionButton,
  WppGrid,
  WppIconChevron,
  WppIconNotification,
  WppIconNotificationNew,
  WppListItem,
  WppMenuContext,
  WppMenuGroup,
  WppTopbarItem,
} from '@platform-ui-kit/components-library-react'
import { AnalyticsActionType } from '@wpp-open/core'
import clsx from 'clsx'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { useFetchFeedsStatusesApi } from 'api/feeds/queries/useFetchFeedsStatusesApi'
import { Avatar } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import { openIntercomWidget } from 'components/intercom/utils'
import { showRequestTenantModal } from 'components/modal/requestTenantModal/RequestTenantModal'
import { NavigationMenu } from 'components/navigationMenu/NavigationMenu'
import {
  hideNotificationsSideModal,
  showNotificationsSideModal,
  useNotificationSideModal,
} from 'components/notificationsSideModal/NotificationsSideModal'
import { OsAssistant } from 'components/osAssistant/OsAssistant'
import { handleOpenWidget } from 'components/zendesk'
import { WIDGET_PAGE_CODES } from 'components/zendesk/utils/constants'
import { useTenantLogoAndMetaData } from 'hooks/useTenantLogoAndMetaData'
import { Breadcrumbs } from 'layout/header/breadcrumbs/Breadcrumbs'
import { useBreadcrumbs } from 'layout/header/breadcrumbs/utils'
import styles from 'layout/header/Header.module.scss'
import { HeaderNavigationProvider } from 'layout/header/headerNavigationProvider/HeaderNavigationProvider'
import { HeaderTeleport } from 'layout/header/HeaderTeleport'
import { UserProfileIconMenu } from 'layout/header/userProfileIconMenu/UserProfileIconMenu'
import { getHeaderAppTag, HelpNavigationValue, isHelpNavigationItem, useHeaderMenu } from 'layout/header/utils'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { useOsRoot } from 'providers/osRoot/OsRootContext'
import { useOsState } from 'providers/osState/OsStateProvider'
import { useOtherTenantsAndUserData } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'
import { trackAnalytics } from 'utils/analytics'
import { EVENTS, getEventPayload } from 'utils/events'
import { simulateLinkClick } from 'utils/links'
import { routesManager } from 'utils/routesManager'

export const Header = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const { defaultTenant } = useOsRoot()
  const { availableTenants, requestableTenants, userDetails } = useOtherTenantsAndUserData()
  const { currentTenant } = useCurrentTenantData()
  const { appData } = useOsState()
  const breadcrumbs = useBreadcrumbs()
  const headerNavigation = useHeaderMenu()
  const { logoThumbnail, logoOriginal } = useTenantLogoAndMetaData()
  const { visible } = useNotificationSideModal()

  const isBreadcrumbsShown = Boolean(breadcrumbs.items.length)
  const navigationItems = isBreadcrumbsShown ? headerNavigation.slice(0, 1) : headerNavigation
  const contextNavigationItems = isBreadcrumbsShown ? headerNavigation.slice(1) : []
  const selectedNavItemValue = headerNavigation.find(({ path }) => path && pathname.startsWith(path))?.value || ''

  const { data: status } = useFetchFeedsStatusesApi()
  const isNewNotification = status.some(feed => feed.existsNew)

  const sortedTenants = useMemo(
    () =>
      availableTenants.filter(tenant => tenant.id !== currentTenant.id).sort((a, b) => a.name.localeCompare(b.name)),
    [availableTenants, currentTenant.id],
  )

  const showTenantList = !!sortedTenants.length || !!requestableTenants.length

  const handleNavigateMenu = (event: CustomEvent<NavigationItemEventDetail>) => {
    const { path, value, label } = event.detail

    switch (value) {
      case HelpNavigationValue.Intercom: {
        trackAnalytics({
          type: AnalyticsActionType.action,
          payload: getEventPayload(EVENTS.HOME_PAGE.ACTIONS.HOME_NAV_MENU_ITEM_CLICK, HelpNavigationValue.Intercom),
        })
        openIntercomWidget()
        break
      }

      case HelpNavigationValue.Marketplace: {
        trackAnalytics({
          type: AnalyticsActionType.page,
          payload: getEventPayload(EVENTS.HOME_PAGE.ACTIONS.HOME_NAV_MENU_ITEM_CLICK, HelpNavigationValue.Marketplace),
        })
        simulateLinkClick({ href: path! })
        break
      }

      case HelpNavigationValue.Support: {
        trackAnalytics({
          type: AnalyticsActionType.page,
          payload: getEventPayload(EVENTS.HOME_PAGE.ACTIONS.HOME_NAV_MENU_ITEM_CLICK, HelpNavigationValue.Support),
        })
        handleOpenWidget({
          userDetails,
          appOrPageCode: getHeaderAppTag(appData) || WIDGET_PAGE_CODES.OS_INTERNAL_PAGE,
        })
        break
      }

      default: {
        if (path) {
          if (isHelpNavigationItem(value)) {
            trackAnalytics({
              type: AnalyticsActionType.action,
              payload: getEventPayload(EVENTS.HOME_PAGE.ACTIONS.HOME_NAV_MENU_ITEM_CLICK, label),
            })
            simulateLinkClick({ href: path, target: '_blank' })
          } else {
            trackAnalytics({
              type: AnalyticsActionType.action,
              payload: getEventPayload(EVENTS.HOME_PAGE.ACTIONS.HOME_NAV_MENU_ITEM_CLICK, label),
            })
            navigate(path)
          }
        }
        break
      }
    }
  }

  return (
    <HeaderNavigationProvider>
      <header className={styles.root}>
        <Flex className={styles.content} align="center">
          <WppGrid container>
            <HeaderTeleport.Target as={WppGrid} item all={24}>
              {/* Default OS header. Will be replaced with the deep most HeaderTeleport.Source rendered inside app */}
              <HeaderTeleport.Source>
                <Flex align="center" gap={12}>
                  <NavigationMenu />

                  <Flex align="center">
                    <Link
                      to={routesManager.home.root.url()}
                      onClick={() =>
                        trackAnalytics({
                          type: AnalyticsActionType.action,
                          payload: EVENTS.HOME_PAGE.ACTIONS.HOME_ICON,
                        })
                      }
                    >
                      {isBreadcrumbsShown ? (
                        <img className={styles.logoCompact} src={logoThumbnail} alt="logo" data-testid="logo-compact" />
                      ) : (
                        <img className={styles.logo} src={logoOriginal} alt="logo" data-testid="logo" />
                      )}
                    </Link>

                    {showTenantList && (
                      <WppMenuContext>
                        <WppActionButton
                          className={styles.tenantMenuTriggerButton}
                          slot="trigger-element"
                          variant="secondary"
                          onClick={() =>
                            trackAnalytics({
                              type: AnalyticsActionType.action,
                              payload: EVENTS.LIST_OF_TENANTS.ACTIONS.LIST,
                            })
                          }
                        >
                          <WppIconChevron className={styles.tenantMenuTriggerIcon} slot="icon-start" direction="down" />
                        </WppActionButton>

                        {sortedTenants.length > 0 && (
                          <WppMenuGroup className={styles.tenantMenuGroup} withDivider={!!requestableTenants.length}>
                            {sortedTenants.map(tenant => (
                              <WppListItem
                                key={tenant.id}
                                linkConfig={{
                                  href: process.env.DEV ? '/' : tenant.homeUrl,
                                }}
                                onClick={() =>
                                  trackAnalytics({
                                    type: AnalyticsActionType.page,
                                    payload: getEventPayload(EVENTS.LIST_OF_TENANTS.PAGE.OPEN_TENANT, tenant.name),
                                  })
                                }
                              >
                                <Avatar
                                  slot="left"
                                  name={tenant.name}
                                  src={tenant.logoThumbnail?.url || defaultTenant.logoThumbnail?.url || undefined}
                                />
                                <span slot="label">{tenant.name}</span>
                              </WppListItem>
                            ))}
                          </WppMenuGroup>
                        )}
                        {!!requestableTenants.length && (
                          <WppListItem
                            onWppChangeListItem={() =>
                              showRequestTenantModal({
                                title: t('os.request_access_modal.tenant.access_another_os'),
                                tenants: requestableTenants,
                              })
                            }
                          >
                            <span slot="label">{t('os.header.tenant_selection.request_access')}</span>
                          </WppListItem>
                        )}

                        {/* This limits the min-width of the list, but allows other items to expand further */}
                        <div className={styles.menuMinWidthLimiter} />
                      </WppMenuContext>
                    )}
                  </Flex>

                  {isBreadcrumbsShown && <Breadcrumbs data={breadcrumbs} />}

                  <Flex className={styles.navigationControls} gap={20}>
                    <Flex gap={4} align="center">
                      {navigationItems.map(navigationItem => (
                        <WppTopbarItem
                          key={navigationItem.value}
                          firstLevel
                          data-testid={navigationItem.value}
                          navigation={navigationItem}
                          activeItems={[selectedNavItemValue]}
                          onWppActiveTopbarItemChange={handleNavigateMenu}
                        />
                      ))}

                      {contextNavigationItems.length > 0 && (
                        <WppTopbarItem
                          firstLevel
                          menu
                          navigation={
                            {
                              children: contextNavigationItems,
                            } as NavigationState
                          }
                          active={contextNavigationItems.some(({ value }) => selectedNavItemValue === value)}
                          activeItems={[selectedNavItemValue]}
                          onWppActiveTopbarItemChange={handleNavigateMenu}
                        />
                      )}
                    </Flex>

                    <OsAssistant />

                    <WppActionButton
                      className={clsx({ [styles.notificationBellVisible]: visible })}
                      onClick={() => (!visible ? showNotificationsSideModal() : hideNotificationsSideModal())}
                      data-testid="notifications-bell-icon"
                    >
                      {isNewNotification ? (
                        <WppIconNotificationNew color={`var(--wpp-grey-color-${visible ? '1000' : '600'})`} />
                      ) : (
                        <WppIconNotification color={`var(--wpp-grey-color-${visible ? '1000' : '600'})`} />
                      )}
                    </WppActionButton>

                    <UserProfileIconMenu />
                  </Flex>
                </Flex>
              </HeaderTeleport.Source>
            </HeaderTeleport.Target>
          </WppGrid>
        </Flex>
      </header>
    </HeaderNavigationProvider>
  )
}
