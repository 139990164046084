import { Category, Country, SocialMedia } from 'legacy/types/osWebRootApi/legacy/brand.model'
import { AzUser } from 'legacy/types/osWebRootApi/user.model'

export enum WorkspaceType {
  Advertiser = 'ADVERTISER',
  AdvertiserMarket = 'ADVERTISER_MARKET',
  Brand = 'BRAND',
  Market = 'MARKET',
  Workspace = 'WORKSPACE',
}

export enum WorkspaceStatus {
  Live = 'LIVE',
  Pitch = 'PITCH',
  Test = 'TEST',
  Inactive = 'INACTIVE',
}

export interface WorkspaceContact {
  name?: string
  email?: string
  phone?: string
  website?: string
  socialMediaLinks?: SocialMedia[]
}

export interface WorkspaceAncestors {
  workspace: LegacyWorkspace
  ancestors: WorkspaceAncestors[]
}

// Legacy workspaces are modified by the OS in different ways depending on WorkspaceType
export interface LegacyWorkspace extends WorkspaceResponse {
  categories?: Category[]
  /** @deprecated - to be removed - apps should use parent to get advertiser id */
  clientId?: string
  code?: string
  contacts?: WorkspaceContact[]
  countries?: string[]
  /** @deprecated - to be removed - apps should use countries instead of country */
  country?: Country
  /** @deprecated - to be removed - apps should use azId instead of id */
  id?: string // @deprecated
  logoUrl?: string
  /** @deprecated - to be removed - apps should not use markets */
  markets?: LegacyWorkspace[] // for brands
  members?: AzUser[] // for advertiser_market
  owners?: AzUser[] // for advertiser_market
  parent?: any // Copied: should be Workspace (changes for legacy-mapper) - breadcrumbs / brand
  parentId?: string
  extraParents?: string[] // for advertiser_market
}

export interface WorkspaceResponse {
  azId: string
  description: string
  name: string
  status: WorkspaceStatus
  type: WorkspaceType
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
}
