import { WppTypography, WppCardGroup, WppSpinner } from '@platform-ui-kit/components-library-react'
import { Tenant } from '@wpp-open/core'
import { RefCallback, useCallback, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useInfiniteFetchFeedsApi } from 'api/feeds/infiniteQueries/useInfiniteFetchFeedsApi'
import { useUpdateFeedsLastSeenAtApi } from 'api/feeds/mutations/useUpdateFeedsLastSeenAtApi'
import { useFetchFeedsStatusesApi } from 'api/feeds/queries/useFetchFeedsStatusesApi'
import { Flex } from 'components/common/flex/Flex'
import { EmptyState } from 'components/notificationsSideModal/EmptyState'
import { NotificationCardSkeleton } from 'components/notificationsSideModal/notificationCard/NotificationCard'
import { NotificationItems } from 'components/notificationsSideModal/notificationItems/NotificationItems'
import { NotificationsSegmentedControl } from 'components/notificationsSideModal/notificationsSegmentedControl/NotificationsSegmentedControl'
import styles from 'components/notificationsSideModal/NotificationsSideModal.module.scss'
import { TenantIconWithNotification } from 'components/notificationsSideModal/tenantIconWithNotification/TenantIconWithNotification'
import { NotificationSegment } from 'components/notificationsSideModal/utils'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { DEFAULT_PLURAL_COUNT } from 'constants/i18n'
import { useInfiniteFetchNextPage } from 'hooks/useInfiniteFetchNextPage'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { queryClient } from 'providers/osQueryClient/utils'
import { useOtherTenantsAndUserData } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'
import { TenantShort } from 'types/tenants/tenant'
import { normalize } from 'utils/common'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

const Skeleton = () =>
  Array(7)
    .fill('')
    .map((_, index) => <NotificationCardSkeleton key={index} />)

const NotificationsSideModal = ({ isOpen, onClose, onCloseComplete, id }: NiceModalWrappedProps) => {
  const { t } = useTranslation()
  const { mutateAsync: handleUpdateFeedsLastSeenAt } = useUpdateFeedsLastSeenAtApi()
  const { data: statuses } = useFetchFeedsStatusesApi()
  const [currentSegment, setCurrentSegment] = useState<NotificationSegment>(NotificationSegment.ALL)

  const { availableTenants } = useOtherTenantsAndUserData()
  const { currentTenant } = useCurrentTenantData()
  const [selectedTenant, setSelectedTenant] = useState<TenantShort | Tenant>(currentTenant)
  const availableTenantsWithoutCurrent = availableTenants.filter(tenant => tenant.id !== currentTenant.id)

  const { data, hasNextPage, fetchNextPage, isFetching, isLoading } = useInfiniteFetchFeedsApi({
    params: {
      itemsPerPage: 50,
      page: 1,
      tenantIds: [selectedTenant.id],
      ...(currentSegment !== NotificationSegment.ALL && { categories: [currentSegment] }),
    },
    staleTime: 60 * 1000,
  })

  const [loadMoreRef, setLoadMoreRef] = useState<HTMLDivElement>(null!)
  const setRef: RefCallback<HTMLDivElement> = useCallback(node => setLoadMoreRef(node!), [])

  useInfiniteFetchNextPage({
    loadMoreRef,
    isFetching,
    fetchNextPage,
    hasNextPage,
  })

  const normalizedStatus = useMemo(() => normalize(statuses, status => status.tenantId), [statuses])

  const updateLastSeenAt = async () => {
    await handleUpdateFeedsLastSeenAt(selectedTenant.id)
    await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.FEEDS_STATUSES] })
  }

  return (
    <SideModal
      data-testid={id}
      open={isOpen}
      className={styles.modal}
      size="m"
      onWppSideModalClose={onClose}
      onWppSideModalCloseComplete={() => {
        onCloseComplete()
        updateLastSeenAt()
      }}
    >
      <>
        <Flex slot="header" justify="between" align="center">
          <Flex align="center">
            {!!availableTenantsWithoutCurrent.length && (
              <WppCardGroup value={selectedTenant.name} withRadioOrCheckbox={false}>
                <div className={styles.mainTenantWrapper}>
                  <TenantIconWithNotification
                    onClick={() => {
                      updateLastSeenAt()
                      setSelectedTenant(currentTenant)
                      setCurrentSegment(NotificationSegment.ALL)
                    }}
                    tenant={currentTenant}
                    hasNewNotification={normalizedStatus[currentTenant.id]?.existsNew}
                  />
                </div>
              </WppCardGroup>
            )}
            <WppTypography className={styles.title} type="xl-heading">
              {t('os.notification.title', { count: DEFAULT_PLURAL_COUNT })} {selectedTenant.name}
            </WppTypography>
          </Flex>
        </Flex>

        <Flex slot="body" gap={10} className={styles.body}>
          {!!availableTenantsWithoutCurrent.length && (
            <WppCardGroup value={selectedTenant.name} withRadioOrCheckbox={false}>
              <Flex direction="column" gap={12} className={styles.allTenantsWrapper}>
                {availableTenantsWithoutCurrent.map(tenant => (
                  <TenantIconWithNotification
                    key={tenant.id}
                    onClick={() => {
                      updateLastSeenAt()
                      setSelectedTenant(tenant)
                      setCurrentSegment(NotificationSegment.ALL)
                    }}
                    tenant={tenant}
                    hasNewNotification={normalizedStatus[tenant.id]?.existsNew}
                  />
                ))}
              </Flex>
            </WppCardGroup>
          )}
          <Flex direction="column" gap={24} className={styles.bodyContent}>
            <NotificationsSegmentedControl
              value={currentSegment}
              onWppChange={({ detail: { value } }) => {
                setCurrentSegment(value as NotificationSegment)
              }}
            />

            <Flex direction="column" gap={10} className={styles.bodyNotifications}>
              <NotificationItems
                feeds={data}
                lastSeenAt={normalizedStatus[selectedTenant.id]?.lastSeenAt}
                selectedTenant={selectedTenant}
              />

              {isLoading && <Skeleton />}

              <Flex justify="center" ref={setRef}>
                {isFetching && !isLoading && <WppSpinner size="m" />}
              </Flex>

              {!isLoading && !isFetching && !data.length && <EmptyState />}
            </Flex>
          </Flex>
        </Flex>
      </>
    </SideModal>
  )
}

export const {
  showModal: showNotificationsSideModal,
  hideModal: hideNotificationsSideModal,
  useModal: useNotificationSideModal,
} = createNiceModal(NotificationsSideModal, 'notifications-side-modal')
