import { WppIconApp, WppIconExternalLink, WppListItem } from '@platform-ui-kit/components-library-react'
import { AnalyticsActionType, HierarchyLevelType, MayBeNull } from '@wpp-open/core'
import { useNavigate } from 'react-router-dom'

import { useHeaderNavigation } from 'layout/header/headerNavigationProvider/HeaderNavigationProvider'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { useOtherTenantsAndUserData } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'
import { NoCodeAppType } from 'types/apps/noCodeApps'
import { trackAnalytics } from 'utils/analytics'
import { AppInstanceApp } from 'utils/appInstances'
import { routesManager } from 'utils/routesManager'

interface Props {
  isChecked: boolean
  app: AppInstanceApp
  customAppName?: MayBeNull<string>
}

export const BreadcrumbMenuAppItem = ({ isChecked, app, customAppName }: Props) => {
  const navigate = useNavigate()
  const { currentTenant, navigationTreeWithHiddenLevel } = useCurrentTenantData()
  const { activeHierarchyWithHiddenLevel } = useHeaderNavigation()
  const { userDetails } = useOtherTenantsAndUserData()

  const appName = customAppName || app.name

  const trackAppOpenAnalytics = () => {
    const activeHierarchyNodes = activeHierarchyWithHiddenLevel.map(
      nodeId => navigationTreeWithHiddenLevel.mapping[nodeId],
    )
    const client = activeHierarchyNodes.find(node => node.type === HierarchyLevelType.Client)
    const market = activeHierarchyNodes.find(node => node.type === HierarchyLevelType.Market)
    const brand = activeHierarchyNodes.find(node => node.type === HierarchyLevelType.Brand)

    const data = {
      tenantId: currentTenant.id,
      tenantName: currentTenant.name,
      source: 'CORE',
      launchedWithContext: activeHierarchyWithHiddenLevel.length > 0,
      market: market?.name || '',
      brand: brand?.name || '',
      client: client?.name || '',
      productName: app.name,
      productType: app.type,
      appId: app.stableId,
      // browserLocation: '', // disabled for now
      userId: userDetails.id,
      userEmail: userDetails.email,
      userAgency: userDetails.agency,
      coreNavigation: false,
      coreBreadcrumbs: true,
    }

    const params = Object.entries(data).map(([key, value]) => ({
      key,
      value,
    }))

    trackAnalytics({
      type: AnalyticsActionType.action,
      payload: {
        action: 'navigation_app_run',
        params: params,
      },
    })
  }

  if (app.type === NoCodeAppType.ExternalLink) {
    return (
      <WppListItem
        linkConfig={{
          target: '_blank',
          rel: 'noreferrer',
          href: app.url,
        }}
        onWppChangeListItem={() => {
          trackAppOpenAnalytics()
        }}
        data-testid="breadcrumbs-menu-app-item"
      >
        <WppIconApp slot="left" />
        <span slot="label">{appName}</span>
        <WppIconExternalLink slot="right" />
      </WppListItem>
    )
  }

  const href = routesManager.app({
    id: app.stableId,
    osRoute: app.osRoute,
  })

  return (
    <WppListItem
      checked={isChecked}
      linkConfig={{
        href,
        onClick: e => e.preventDefault(),
      }}
      onWppChangeListItem={() => {
        if (!isChecked) {
          trackAppOpenAnalytics()
          navigate(href)
        }
      }}
      data-testid="breadcrumbs-menu-app-item"
    >
      <WppIconApp slot="left" />
      <span slot="label">{appName}</span>
    </WppListItem>
  )
}
