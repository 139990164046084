import { Outlet } from 'react-router-dom'

import { Header } from 'layout/header/Header'
import { HideHeader } from 'layout/hideHeader/HideHeader'
import styles from 'layout/SecureLayout.module.scss'
import { isEmbedded } from 'utils/layout'

export const SecureLayout = () => (
  <div className={styles.root}>
    {isEmbedded() ? <HideHeader /> : <Header />}
    <Outlet />
  </div>
)
