import { MayBeNull } from '@wpp-open/core'

import { BaseAppConfig } from 'types/apps/apps'
import { DevHubAppVersionShort, DevHubNoCodeAppBaseShort } from 'types/apps/devHubApps'

export enum NoCodeAppType {
  EmbeddedLink = 'EMBEDDED_LINK',
  ExternalLink = 'EXTERNAL_LINK',
  PageBuilder = 'PAGE_BUILDER',
  EmbeddedCode = 'EMBEDDED_CODE',
  MiroBoard = 'MIRO_BOARD',
  CodeUpload = 'CODE_UPLOAD',
}

export type NoCodeApp =
  | NoCodeAppEmbeddedLink
  | NoCodeAppExternalLink
  | NoCodeAppPageBuilder
  | NoCodeAppCodeEmbedded
  | NoCodeAppMiroBoard
  | NoCodeCodeUpload

export type RenderableNoCodeApp = Exclude<NoCodeApp, NoCodeAppExternalLink>

export interface NoCodeAppEmbeddedLink extends BaseAppConfig {
  type: NoCodeAppType.EmbeddedLink
  osRoute: string
  url: string
}

export interface NoCodeAppExternalLink extends BaseAppConfig {
  type: NoCodeAppType.ExternalLink
  url: string
}

export interface NoCodeAppPageBuilder extends BaseAppConfig {
  type: NoCodeAppType.PageBuilder
  osRoute: string
  pageId: string
  externalConfigId: string
}

export interface NoCodeAppCodeEmbedded extends BaseAppConfig {
  type: NoCodeAppType.EmbeddedCode
  osRoute: string
  embeddedCode: string
}

export interface NoCodeAppMiroBoard extends BaseAppConfig {
  type: NoCodeAppType.MiroBoard
  osRoute: string
  url: MayBeNull<string>
}

export interface NoCodeCodeUpload extends BaseAppConfig {
  type: NoCodeAppType.CodeUpload
  osRoute: string
  url: string
}

export type NoCodeAppShort =
  | EmbeddedCodeDevHubApp
  | MiroBoardDevHubApp
  | ExternalLinkDevHubApp
  | PageBuilderDevHubApp
  | EmbeddedLinkDevHubApp
  | CodeUploadDevHubApp

export interface EmbeddedCodeAppConfig {
  embeddedCode: string
  routePath: string
}

export interface MiroBoardAppConfig {
  routePath: string
  boardId: string
  frames: MiroBoardAppFrame[]
}

export interface MiroBoardAppFrame {
  id: string
  name: string
}

export interface ExternalLinkConfig {
  routePath: string
  url: string
  appType: string
}

export interface PageBuilderConfig {
  routePath: string
  externalConfigId: string
}

export interface EmbeddedLinkConfig {
  url: string
  appType: string
  routePath: string
}

export interface CodeUploadAppConfig {
  appType: string
  baseUrl?: string
  fileUrls?: string[]
  homePage?: string
  routePath: string
  archiveMeta?: {
    key: string
    name: string
    size: number
  }
  homePageUrl?: string
  fileAttachments?: {
    key: string
    name: string
    size: number
  }[]
}

export interface ExternalLinkVersion extends DevHubAppVersionShort {
  config: ExternalLinkConfig
}

export interface MiroBoardAppVersion extends DevHubAppVersionShort {
  config: MiroBoardAppConfig
}

export interface PageBuilderAppVersion extends DevHubAppVersionShort {
  config: PageBuilderConfig
}
export interface EmbeddedLinkVersion extends DevHubAppVersionShort {
  config: EmbeddedLinkConfig
}

export interface EmbeddedCodeAppVersion extends DevHubAppVersionShort {
  config: EmbeddedCodeAppConfig
}

export interface CodeUploadAppVersion extends DevHubAppVersionShort {
  config: CodeUploadAppConfig
}

export type NoCodeAppVersion =
  | EmbeddedCodeAppVersion
  | ExternalLinkVersion
  | MiroBoardAppVersion
  | PageBuilderAppVersion
  | EmbeddedLinkVersion
  | CodeUploadAppVersion

export interface EmbeddedCodeDevHubApp extends DevHubNoCodeAppBaseShort {
  noCodeAppType: NoCodeAppType.EmbeddedCode
  versions: EmbeddedCodeAppVersion[]
}

export interface MiroBoardDevHubApp extends DevHubNoCodeAppBaseShort {
  noCodeAppType: NoCodeAppType.MiroBoard
  versions: MiroBoardAppVersion[]
}

export interface ExternalLinkDevHubApp extends DevHubNoCodeAppBaseShort {
  noCodeAppType: NoCodeAppType.ExternalLink
  versions: ExternalLinkVersion[]
}

export interface PageBuilderDevHubApp extends DevHubNoCodeAppBaseShort {
  noCodeAppType: NoCodeAppType.PageBuilder
  versions: PageBuilderAppVersion[]
}

export interface EmbeddedLinkDevHubApp extends DevHubNoCodeAppBaseShort {
  noCodeAppType: NoCodeAppType.EmbeddedLink
  versions: EmbeddedLinkVersion[]
}

export interface CodeUploadDevHubApp extends DevHubNoCodeAppBaseShort {
  noCodeAppType: NoCodeAppType.CodeUpload
  versions: CodeUploadAppVersion[]
}
