import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as zod from 'zod'

import { SignUpRequestFormDto } from 'api/signUp/fetchers/createSignUpRequestApi'

export const defaultValues: SignUpRequestFormDto = {
  email: '',
  firstName: '',
  lastName: '',
}

export const useSignUpValidationScheme = () => {
  const { t } = useTranslation()

  return useMemo(
    () =>
      zod.object({
        email: zod
          .string()
          .min(1, t('os.common.errors.provide_entity', { entity: t('os.sign_up_modal.work_email') }))
          .email(t('os.common.errors.invalid_format')),
        firstName: zod
          .string()
          .min(
            1,
            t('os.common.errors.min_max_length', {
              entity: t('os.admin.tenants.modal.first_name'),
              min: 1,
              max: 100,
            }),
          )
          .regex(
            /^[^\s]+(\s+[^\s]+)*$/,
            t('os.admin.tenants.modal.validation.space', {
              entity: t('os.admin.tenants.modal.first_name'),
            }),
          )
          .max(
            100,
            t('os.common.errors.min_max_length', {
              entity: t('os.admin.tenants.modal.first_name'),
              min: 1,
              max: 100,
            }),
          ),
        lastName: zod
          .string()
          .min(
            1,
            t('os.common.errors.min_max_length', {
              entity: t('os.admin.tenants.modal.last_name'),
              min: 1,
              max: 100,
            }),
          )
          .regex(
            /^[^\s]+(\s+[^\s]+)*$/,
            t('os.admin.tenants.modal.validation.space', {
              entity: t('os.admin.tenants.modal.last_name'),
            }),
          )
          .max(
            100,
            t('os.common.errors.min_max_length', {
              entity: t('os.admin.tenants.modal.last_name'),
              min: 1,
              max: 100,
            }),
          ),
      }),
    [t],
  )
}
