import { WppCard, WppTypography } from '@platform-ui-kit/components-library-react'
import { Trans, useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import styles from 'components/notificationItemSideModal/NotificationItemSideModal.module.scss'
import { WPP_OPEN_SUPPORT_EMAIL } from 'constants/common'
import { FeedContext } from 'types/feeds/feeds'
import { mailto } from 'utils/links'

export const HierarchyRejectedSideModalContent = ({
  rejectReason,
  requestReason,
}: {
  rejectReason: FeedContext['reject_reason']
  requestReason: FeedContext['request_reason']
}) => {
  const { t } = useTranslation()

  return (
    <Flex direction="column" gap={16}>
      <Flex direction="column" gap={8}>
        <WppTypography type="s-body" data-testid="notification-item-content-hierarchy-rejected">
          <Trans
            i18nKey="os.notification.access.rejected_access"
            values={{
              type: t('os.notification.access.hierarchy.navigation'),
            }}
            components={[
              <b key="0" className={styles.bold}>
                type
              </b>,
            ]}
          />
        </WppTypography>
        {rejectReason && (
          <WppTypography type="s-body" data-testid="notification-item-reject-reason">
            {rejectReason}
          </WppTypography>
        )}
      </Flex>

      {requestReason && (
        <WppCard variant="secondary">
          <Flex direction="column" gap={4}>
            <WppTypography
              type="xs-strong"
              className={styles.greyTypography}
              data-testid="notification-item-request-reason-title"
            >
              {t('os.notification.access.your_request')}
            </WppTypography>
            <WppTypography
              type="xs-body"
              className={styles.greyTypography}
              data-testid="notification-item-request-reason"
            >
              {requestReason}
            </WppTypography>
          </Flex>
        </WppCard>
      )}

      <WppTypography type="xs-body" className={styles.greyTypography} data-testid="notification-item-support">
        <Trans
          i18nKey="os.notification.access.support"
          values={{
            supportEmail: WPP_OPEN_SUPPORT_EMAIL,
          }}
          components={[
            <a key="0" href={mailto(WPP_OPEN_SUPPORT_EMAIL)}>
              supportEmail
            </a>,
          ]}
        />
      </WppTypography>
    </Flex>
  )
}
